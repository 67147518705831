<div class="container-fluid d-flex flex-column">
  <div class="row justify-content-center">
    <p class='h2'>Teie liitumis kood on:</p>
  </div>
  <div class="row justify-content-center">
    <p class="h1">{{joinCode}}</p>
  </div>
  <div class="mt-3 pt-3"></div>
  <div class='row'>
    <div class='col-6 d-flex flex-column'>
      <button class='btn btn-outline-primary' [routerLink]="['../', currentId,  'ulevaade']">Vaata mängu</button>
    </div>
    <div class='col-6 d-flex flex-column'>
      <button class='btn btn-outline-danger' (click)="deleteGame()">Lõpeta mäng</button>
    </div>
  </div>
</div>

<div class="row fixed-bottom text-center mx-auto">
  <div class="col">
    <button class="btn btn-info" (click)="backClicked()">Tagasi</button>
  </div>
</div>

<app-tutorial [modalTitle]="'Tere tulemast aktiveeritud mängu lehele'"
              [modalBody]="'<p>Siin saate jälgida toimuvat mängu realajas selleks vajutage \' Vaata mängu \' nupule.</p>' + '' +
               '<p>Kui mängijad on tagasi naasnud ja oma mängu ära lõpetanud saate lõpetata mängu vajutades  \' Lõpeta mäng \'</p>' + '' +
                '<p>Mäng on lõpetatud, siis on võimalik vaadata mängu tulemust. Avades küljemenüüst \' Lõpetatud mängud \'</p>'"
              [showModal]="getShowModal()"
              [isTutorialActive]="false"

></app-tutorial>
