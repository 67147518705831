<div class="container">
  <div class="row" >
    <div class="col-12">
      <h1>Sisesta ruumi kood</h1>
    </div>
  </div>
  <div class="row buttons">
    <div class="col-lg-6 mx-auto text-center">
      <form class="form-row align-items-center" autocomplete="off">
        <div class="col">
          <input id='joinCode' class="form-control text-center rounded-pill shadow" #joinCode>
          <button type="submit" class="btn btn-dark rounded-pill mt-2" (click)="checkCode()"><span>Mängima</span></button>
        </div>
      </form>
    </div>
  </div>
</div>
