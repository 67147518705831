
<div class="sidepanel container-fluid">
  <div class="row pt-4">
    <br>
    <div class='col-12 d-flex justify-content-center text-center'>
      <div>
        <span class='h5 text-light'>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#DB077C" class="bi bi-clock-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
          </svg>
        </span>
      </div>
    </div>
    <div class='col-12 d-flex justify-content-center text-center'>
      <div>
        <span class='h5 text-dark'>{{hours}}:{{minutes}}:{{seconds}}</span>
      </div>
    </div>
    <div class="icon-fixed-pos marker">
        <span class='h5 text-dark'>{{questionsLeft}}</span>
        <span class='h5 text-light mr-3'>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#DB077C" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
          </svg>
        </span>
    </div>
    <div class="icon-fixed-pos score">
      <span class='h5 text-light ml-3'>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#DB077C" class="bi bi-trophy-fill" viewBox="0 0 16 16">
          <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935z"/>
        </svg>
      </span>
      <span class='h5 text-dark'>{{score}}</span>
    </div>
  </div>
</div>

<span class="full-screen-icon" (click)="toggleFullScreen()">
  <svg *ngIf="!isFullScreen" xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-arrows-fullscreen" viewBox="0 0 20 20">
    <path fill-rule="evenodd" d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z"/>
  </svg>
  <svg *ngIf="isFullScreen" xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-fullscreen-exit" viewBox="0 0 20 20">
  <path d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z"/>
</svg>
</span>
<div id="map" class="map"></div>

<jw-modal id="checkBoxQuestion">
    <div class="modal-body mb-3">
        <form [formGroup]="form" (ngSubmit)="checkanswer('CHECKBOX')">
          <p class='h2'>{{questionText}}</p>
          <div class="form-check" *ngFor="let choice of choices">
            <input class="form-check-input" (change)="onChange(choice, $event.target.checked)" name="{{ choice }}" type="checkbox" id="{{choice}}">
            <label class="form-check-label" for="{{choice}}">
                <p class='h4 mb-2'>  {{choice}}</p>
            </label>
          </div>
          <button type="submit" class="btn btn-primary float-right">Vasta</button>
        </form>
    </div>
</jw-modal>

<jw-modal id="textQuestion">
    <div class="modal-body mb-3">
        <form [formGroup]="form" (ngSubmit)="checkanswer('TEXT')">
          <div class="form-group">
              <label for="answer" class='h2'>{{questionText}}</label>
              <p class='h4'>Hint:  {{hint}}</p>
              <input [(ngModel)]="textAnswer" id="answer" placeholder="Kirjutage vastus" class="form-control" required [ngModelOptions]="{standalone: true}">
          </div>
          <button type="submit" class="btn btn-primary float-right">Vasta</button>
        </form>
    </div>
</jw-modal>

<jw-modal id="multipleChoiceQuestion">
    <div class="modal-body mb-3">
        <form [formGroup]="form" (ngSubmit)="checkanswer('MULTIPLE_CHOICE')">
          <div class="form-group">
              <label for="answers" class='h2'>{{questionText}}</label>
              <select [(ngModel)]="selectedAnswer" formControlName="answers" id="answers" class="form-control">
                <option [ngValue]="null" class='h3' [disabled]="disabledSelect"></option>
                <option *ngFor="let choice of choices; let i = index" [ngValue]="choice" class='h3'>
                  <p>{{choices[i]}}</p>
                </option>
              </select>
          </div>
          <button type="submit" class="btn btn-primary float-right">Vasta</button>
        </form>
    </div>
</jw-modal>

<jw-modal id="correctAnswer" class='bg-success'>
  <button type="button" class="close" (click)="closeModal('correctAnswer')" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <p class="h2 green text-center text-success text-bold">Õige vastus!</p>
  <br>
  <p class="h1 text-center">Te saite  <span class='font-weight-bold text-success'>{{pointsTrue}}</span> punkti</p>
  <p class="h1 text-center mt-3">Teie hetkeseis on <span class='font-weight-bold text-info'>{{score}}</span></p>
</jw-modal>

<jw-modal id="wrongAnswerText">
  <button type="button" class="close" (click)="closeModal('wrongAnswerText')" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <p class="h2 text-center text-danger">Teie vastus oli vale.</p>
  <br>
  <p class="h1 text-center">Õige vastus oli <span class='text-success text-bold'>{{answer}}</span></p>
  <p class="h1 text-center mt-3">Teie hetkeseis on <span class='font-weight-bold text-info'>{{score}}</span></p>
</jw-modal>

<jw-modal id="wrongAnswerCheckBox">
  <button type="button" class="close" (click)="closeModal('wrongAnswerCheckBox')" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <p class="h2 text-center text-danger">Teie vastus oli vale.</p>
  <br>
  <p class='h2 text-center text-info'>Õiged vastused olid <span class='text-success text-bold'>{{answers}}</span></p>
  <p class="h1 text-center mt-3">Teie hetkeseis on <span class='font-weight-bold text-info'>{{score}}</span></p>
</jw-modal>

<jw-modal id="wrongAnswerMultipleChoice">
  <button type="button" class="close" (click)="closeModal('wrongAnswerMultipleChoice')" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <p class="h2 text-center text-danger">Teie vastus oli vale.</p>
  <br>
  <p class="h1 text-center"> Õige vastus oli <span class='text-success text-bold'>{{answer}}</span></p>
  <br>
  <p class="h1 text-center mt-3">Teie hetkeseis on <span class='font-weight-bold text-info'>{{score}}</span></p>
</jw-modal>

<jw-modal id="gameEnded">
  <button type="button" class="close" (click)="closeModal('gameEnded')" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
    <p class='h1 text-center text-bold'>Mäng lõppes!</p>
    <br>
    <p class="h1 text-center mt-3">Teie lõppseis on <span class='font-weight-bold text-info'>{{score}}</span></p>
    <div class="modal-footer">
      <button class="btn btn-primary" routerLink="/mang" data-dismiss="modal">Tagasi menusse</button>
    </div>
</jw-modal>
