<div id="map" class="map"></div>

<jw-modal id="createPair">
  <button type="button" class="close" (click)="resetForm()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class='container'>
  <div class="form-group space-up">
    <label for="questionsets" class="font-weight-bold col-sm-2 col-form-label">Küsimustik</label>
    <select id="questionsets" [(ngModel)]="selectedQuestionSet" (change)="updateQuestions()" class="form-control">
      <option *ngFor="let c of questionSetdata" [ngValue]="c.id">{{c.name}}</option>
    </select>
    <div class="form-group">
    <label for="questions" class="font-weight-bold col-sm-2 col-form-label">Küsimus</label>
    <select id="questions" [(ngModel)]="selectedQuestion" class="form-control">
      <option *ngFor="let c of questions" [ngValue]="c.id">{{c.questionText}}</option>
    </select>
  </div>
    <button (click)='postPair()' class="btn btn-primary float-right">Loo uus paar</button>
    <div class='pb-3'></div>
  </div>
</div>
</jw-modal>

<jw-modal id="editPair">
  <button type="button" class="close" (click)="resetPairForm()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class='container-fluid d-flex flex-column text-center'>
    <p class='h3 text-info'>Paari küsimus:</p>
    <p class='h3'>{{questionText}}</p>
    <button (click)='deletePair()' class="btn btn-danger float-right">Kustuta paar</button>
  </div>
</jw-modal>
