import { Component, OnInit } from '@angular/core';
import {OAuthService} from '../../services/o-auth.service';

@Component({
  selector: 'app-greeting',
  templateUrl: './greeting.component.html',
  styleUrls: ['./greeting.component.css', '../../app.component.css']
})
export class GreetingComponent implements OnInit {

  constructor(public authService: OAuthService) { }

  ngOnInit(): void {
  }
}
