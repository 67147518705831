import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Archive, ArchiveTeams} from '../interfaces/archive';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ArchiveService {

  private archiveUrl = 'api/archives';
  httpOptions = {
    headers: new HttpHeaders({'Content-type': 'application/json'})
  };
  constructor(private http: HttpClient) {
  }

  getArchiveById(id: number): Observable<Archive> {
    return this.http.get<Archive>(this.archiveUrl + '/' + id);
  }

  getAllArchive(): Observable<Archive> {
    return this.http.get<Archive>(this.archiveUrl);
  }

  getArchiveByUserId(id: number): Observable<Archive[]> {
    return this.http.get<Archive[]>(this.archiveUrl + '/all/' + id);
  }

  getAllArchivedTeamsByArchiveId(id: number): Observable<ArchiveTeams[]> {
    return this.http.get<ArchiveTeams[]>(this.archiveUrl + '/archived_teams/' + id);
  }
}
