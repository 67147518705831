import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ModalService} from '../../services/modal.service';
import {TutorialService} from '../../services/tutorial.service';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.css']
})
export class TutorialComponent implements OnInit {
  @ViewChild('modalClick') modalClick: ElementRef;
  isChecked: boolean;

  @Input() modalTitle: string;
  @Input() modalBody: string;
  @Input() showModal: boolean;
  @Input() isTutorialActive = true;

  constructor(public modalService: ModalService,
              public tutorialService: TutorialService) { }

  ngOnInit(): void {
    if (!this.showModal) {
      setTimeout(() => {
        this.modalClick.nativeElement.click();
      }, 200);
    }
  }

  openModal(id: string): void {
    if (undefined !== document.getElementById('tutorialModal')) {
      this.modalService.open(id);
    }
  }

  closeModal(id: string): void {
    this.modalService.close(id);
  }

  checkForIsShowModal(event: any): void {
    this.modalService.setShowModal(event);
  }
}
