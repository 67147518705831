import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Questionset} from '../../interfaces/questionset';
import {QuestionSetsService} from '../../services/question-sets.service';
import {ModalService} from '../../services/modal.service';
import {Router} from '@angular/router';
import {TutorialService} from '../../services/tutorial.service';
import {OAuthService} from '../../services/o-auth.service';

@Component({
  selector: 'app-question-sets',
  templateUrl: './question-sets.component.html',
  styleUrls: ['./question-sets.component.css', '../users-rooms/users-rooms.component.css', '../../app.component.css']
})
export class QuestionSetsComponent implements OnInit, OnDestroy {
  data: Questionset[];
  redirectTo: Questionset;
  private subscriptions: Subscription[] = [];

  constructor(private questionSetsService: QuestionSetsService,
              public modalService: ModalService,
              private router: Router,
              public tutorialService: TutorialService,
              private oAuthService: OAuthService) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.getSets(true);
    }, 400);
  }
  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getSets(firstLoad: boolean): void {
    const sub = this.questionSetsService.getQuestionSetsByUserId(this.oAuthService.getCurrentAccount().id).subscribe(response => {
      this.data = response;
    }, (err) => {
      alert(err);
    },
      () => {
        this.subscriptions.push(sub);
        if (!firstLoad) {
          this.redirectTo = this.data.slice(-1)[0];
          const currentUrl = this.router.url;
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate([currentUrl, this.redirectTo.id, this.redirectTo.name]);
          });
        }
      });
  }

  addSet(): void {
    const setName = (document.getElementById('input') as HTMLInputElement).value;
    const sub = this.questionSetsService.addQuestionSet({name: setName, creatorId: this.oAuthService.getCurrentAccount().id} as Questionset)
      .subscribe(() => {
        this.getSets(false);
      });
    this.subscriptions.push(sub);
  }

  removeSet(id: number): void {
    const sub = this.questionSetsService.removeSet(id)
      .subscribe(() => {
        this.getSets(true);
      },
      error => {
        const errorMessage = error.message;
        console.error('Happened this during deleting: ', errorMessage);
    });
    this.subscriptions.push(sub);
  }

  openModalForDeleting(id: string, element: Questionset): void {
    this.modalService.open(id);
    this.modalService.setElementQuestionSet(element);
  }

  openModalForCreating(id: string): void {
    this.modalService.open(id);
  }

  closeModal(id: string): void {
    this.modalService.close(id);
  }

  getShowModal(): boolean {
    return this.modalService.getShowModal();
  }
}
