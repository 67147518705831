import {Component, OnDestroy, OnInit} from '@angular/core';
import {Archive} from '../../interfaces/archive';
import {ArchiveService} from '../../services/archive.service';
import {OAuthService} from '../../services/o-auth.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-archiev',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.css', '../users-rooms/users-rooms.component.css', '../../app.component.css']
})
export class ArchiveComponent implements OnInit, OnDestroy {
  data: Archive[];
  private subscriptions: Subscription[] = [];
  sortByDate: boolean;

  constructor(
    private archiveService: ArchiveService,
    private oAuthService: OAuthService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.getArchivesByUserId();
    }, 400);
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getArchivesByUserId(): void {
    const sub = this.archiveService.getArchiveByUserId(this.oAuthService.getCurrentAccount().id).subscribe(response => {
        this.data = response;
      }, (err) => {
        alert(err);
      },
      () => {
        this.subscriptions.push(sub);
      }
    );
  }

  sortArchiveDate(sort: string): void {
    if (sort === 'dateNewer') {
      this.data.sort((q1, q2) => (new Date(q2.dateCreated) as any) - (new Date(q1.dateCreated) as any));
    }
    if (sort === 'dateOlder') {
      this.data.sort((q1, q2) => (new Date(q1.dateCreated) as any) - (new Date(q2.dateCreated) as any));
    }
    if (sort === 'gameNameAsc') {
      this.data.sort((q1, q2) => (q1.gameName) > (q2.gameName) ? 1 : -1);
    }
    if (sort === 'gameNameDesc') {
      this.data.sort((q1, q2) => (q2.gameName) > (q1.gameName) ? 1 : -1);
    }
  }
}
