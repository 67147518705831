<div class="container">
  <div id="goPlayRoom" class="container col-lg-6 align-self-center text-center p-3">
    <form class="form align-items-center" [formGroup]="orderForm">
      <div class="form-group" >
        <label class="h4" for="teamName" >Sisesta tiimi nimi</label>
        <input id="teamName" class="form-control mx-auto w-75 shadow" required>
      </div>
      <div class="form-group">
        <label class="h4" >Sisesta tiimi liikmed</label>
      </div>
      <div
        class="form-group"
        formArrayName="items"
        *ngFor="let item of orderForm.get('items')['controls']; let i = index;"
      >
        <div class="input-group" [formGroupName]="i">
          <input class="form-control mx-auto shadow" formControlName="name" placeholder="Tiimiliikme nimi" (ngModelChange)="onChange()">
          <button class="btn btn-danger" (click)="removeTeamMember(i)">Eemalda</button>
        </div>
      </div>
      <button *ngIf="formTeamMembersNames.length <= 9" class="btn badge-success" type="button" (click)="addTeamMember()">Lisa tiimiliige</button>

      <div class="col mt-3 h-25 w-auto mx-auto">
        <button (click)="goPlay()" class='btn btn-outline-info btn-lg'>Alusta</button>
      </div>
    </form>
  </div>

  <div class="container text-center your-rooms mt-4">
    <label class="display-4 font-weight-bold">Registeeritud Meeskonnad</label>
  </div>
  <div class="container text-center">
    <h2>Tiime kokku {{teams?.length || '0'}}</h2>
    <div class="row justify-content-center" style="background: #F5F5F7; border-radius: 5px">
      <div class="col m-1" *ngFor="let team of teams; index as i" style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; border-radius: 10px">
        <div class="col">
          <div class="row">
            <span class="mx-auto h5">{{ team.teamName }}</span>
          </div>
          <div class="row border-top">
            <span class="mx-auto">{{ team.teamMembers}}</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
