<div class="row w-100 h-100">
  <div class="col-10">
    <div class="row">
      <div class="col-md-3 col-sm-6 mt-3" >
        <div class="card text-center" (click)="openModalForCreating('createNewQuestionSet');" [ngClass]="{'selected': !tutorialService.getIfGoingToCreateNewQuestionSet()}">
          <h4 class="card-title"><br></h4>
          <div class="text-center iconSvg">
            <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
              <path id="plus1" fill="#342b60" d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path id="plus2" fill="#342b60" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
            </svg>
          </div>
          <h3 class="mt-3 mb-3">Loo uus küsimustik</h3>
          <p class="card-text">Vajuta siia, et luua uus küsimustik</p>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 mt-3" *ngFor="let questionSet of data; index as i" >
        <div class="card text-center" [ngClass]="{'active': tutorialService.getTutorialActive()}">
          <h4 class="card-title text-right" (click)="openModalForDeleting('areYouSureDelete', data[i])"><i class="material-icons">close</i></h4>
          <div class="text-center iconSvg" [routerLink]="[questionSet.id, questionSet.name]">
            <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" class="bi bi-pencil" viewBox="0 0 16 16">
              <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
            </svg>
          </div>
          <h3 class="mt-3 mb-3">{{questionSet.name}}</h3>
          <p class="card-text">Vajuta siia, et muuta küsimustiku</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row fixed-bottom text-center mx-auto">
  <div class="col">
    <button class="btn btn-info" routerLink="..">Tagasi</button>
  </div>
</div>

<jw-modal id="createNewQuestionSet">
  <div class="col-sm-12 col-md-12 col-lg-12 form text-center">
    <h2>Loo uus küsimustik</h2>
    <div>
      <input type="text" class="form-control" placeholder="Uue küsimustiku nimi" id='input'>
    </div>
    <div class="modal-footer">
      <button type="submit" (click)="addSet(); closeModal('createNewQuestionSet'); tutorialService.setIfGoingToCreateNewQuestionSet(true)" class="btn btn-modal btn-success mx-auto">Lisa uus küsimustik</button>
      <button type="button" class="btn btn-modal btn-secondary mx-auto" (click)="closeModal('createNewQuestionSet');">Sulge</button>
    </div>
  </div>
</jw-modal>

<jw-modal id="areYouSureDelete">
  <div class="col-sm-12 col-md-12 col-lg-12 form text-center" *ngIf="this.modalService.getElementQuestionSet() !== undefined">
    <h2>Olete kindel kustutamas küsimustikku <i class="font-weight-bold"> {{this.modalService.getElementQuestionSet().name}} </i></h2>
    <div class="modal-footer">
      <button type="submit" (click)="removeSet(this.modalService.getElementQuestionSet().id); closeModal('areYouSureDelete');" class="btn btn-modal btn-danger mx-auto">Kustuta</button>
      <button type="button" class="btn btn-modal btn-secondary mx-auto" (click)="closeModal('areYouSureDelete');">Sulge</button>
    </div>
  </div>
</jw-modal>

<app-tutorial *ngIf="!tutorialService.getIfGoingToCreateNewQuestionSet()"
              [modalTitle]="'Tere tulemast küsimustiku loomise lehele'"
              [modalBody]="'<h3>Kuidas luua küsimustikku?</h3>' +
               '<p>Küsimustikud on kogumikud, kuhu salvestatakse küsimused.</p>' +
               ' <p>Vajutage \'Loo uus küsimustik\' peale.</p>' +
               ' <p>Andke küsimustikule nimi, mille järel kuvatakse leht, kus saate luua/muuta küsimusi ning nende tüüpe. </p>'"
              [showModal]="getShowModal()"
></app-tutorial>

<app-tutorial *ngIf="tutorialService.getIfGoingToCreateNewQuestionSet()"
              [modalTitle]="'Küsimustikud loodud'"
              [modalBody]="'<p>Suunduge küljemenüüst Paarid</p>'"
              [showModal]="getShowModal()"
              [isTutorialActive]="true"
></app-tutorial>
