import { RoomService } from './../../services/room.service';
import { PairsetsService } from './../../services/pairsets.service';
import { ModalService } from '../../services/modal.service';
import { PairDetail } from '../../interfaces/pairDetails';
import { Location } from '../../interfaces/location';
import { LocationService } from '../../services/location.service';
import { QuestionService } from '../../services/question.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationsetsService } from '../../services/locationsets.service';
import { QuestionSetsService } from '../../services/question-sets.service';
import { Locationset } from '../../interfaces/locationset';
import { Questionset } from '../../interfaces/questionset';
import { PairService } from '../../services/pair.service';
import { Location as URL } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Question } from 'src/app/interfaces/question';
import { NotificationsService} from 'angular2-notifications';
import {TutorialService} from '../../services/tutorial.service';
import {OAuthService} from '../../services/o-auth.service';
import {CreateNewRoom} from './messages';


@Component({
  selector: 'app-create-new-room',
  templateUrl: './create-new-room.component.html',
  styleUrls: ['./create-new-room.component.css', '../../app.component.css']
})
export class CreateNewRoomComponent implements OnInit {
  questionSetdata: Questionset[] = [];
  locationSetdata: Locationset[] = [];
  questions: Question[] = [];
  locations: Location[] = [];
  preAssignedGamemode = false; // Based on gamemode changes page layout
  pairDetails: PairDetail[] = [];
  selectedQuestionSet;
  selectedLocationSet;
  selectedMap;
  currentId;
  pairSetId;
  minAmount = 0;
  messages: { pairActions: { iconSvg: string; openModal: string; title: string; subText: string }[];
    preAssignedAction: { iconSvg: string; openModal: string; title: string; subText: string }[];
    genActions: ({ iconSvg: string; openModal: string; title: string; subText: string })[] };
  constructor(private location: URL,
              private router: Router,
              private pairsetS: PairsetsService,
              private locationSetS: LocationsetsService,
              private questionSetS: QuestionSetsService,
              private questionService: QuestionService,
              private locationService: LocationService,
              private roomService: RoomService,
              private pairS: PairService,
              public modalService: ModalService,
              private activatedRoute: ActivatedRoute,
              private nService: NotificationsService,
              public tutorialService: TutorialService,
              private oAuthService: OAuthService
  ) {
    this.messages = CreateNewRoom;
  }
  ngOnInit(): void {
    this.activatedRoute.parent.params.subscribe(
      (params) => {
        this.currentId = +params.id;
        this.getPairSetId();
        this.getRoom();
      });
    this.getLocationSets();
    setTimeout(() => {
      this.getQuestionSets();
    }, 400);
  }
  getRoom(): void{
    this.roomService.getRoomById(this.currentId).subscribe(room => this.preAssignedGamemode = (room.gameMode === 'PREASSIGNED'));
  }

  onSuccess(message): void {
    this.nService.success('Edukas', message, {
      position: ['bottom', 'right'],
      timeOut: 2000,
      animate: 'fade',
      showProgressBar: true
    });
  }
  onError(message): void {
    this.nService.error('Viga', message, {
      position: ['bottom', 'right'],
      timeOut: 2000,
      animate: 'fade',
      showProgressBar: true
    });
  }

  openModal(id: string): void {
    this.modalService.open(id);
  }
  closeModal(id: string): void {
    this.modalService.close(id);
  }

  resetForm(): void{
    this.selectedMap = undefined;
    this.closeModal('manualGen');
  }
  goGenerate(): void{
    if (this.selectedMap){
      this.router.navigate(['roomediting/' + this.currentId + '/ruumid/manual/' + this.selectedMap]);
    }else{
      this.onError('Vali Kaardistik');
    }
  }
  goGeneratePairSets(): void{
    if (this.selectedMap){
      this.router.navigate(['roomediting/' + this.currentId + '/ruumid/pairset/' + this.selectedMap]);
    }else{
      this.onError('Vali Kaardistik');
    }
  }
  getQuestionSets(): void {
    this.questionSetS.getQuestionSetsByUserId(this.oAuthService.getCurrentAccount().id).subscribe(sets => this.questionSetdata = sets);
  }
  getLocationSets(): void {
    this.locationSetS.getLocationSets().subscribe(sets => this.locationSetdata = sets);
  }
  backClicked(): void {
    this.location.back();
  }
  addPair(): void {
    if (this.selectedQuestionSet && this.selectedLocationSet) {
      this.minAmount = Math.min(this.questions.length, this.locations.length);
      if (this.minAmount !== 0) {
        for (let i = 0; i < this.minAmount; i++) {
          const pairObj = {
            questionId: this.questions[i].id,
            locationId: this.locations[i].id,
            pairSetId: this.pairSetId,
            disappearType: 'CORRECT',
          };
          this.pairS.postPair(pairObj)
            .subscribe(pair => {
              this.getPairDetails(this.locations[i].id, this.questions[i].id, pair.id);
            },
              error => {
                const errorMessage = error.message;
                console.error('Happened this during posting: ', errorMessage);
              });
        }
        this.tutorialService.setIfGenPairAuto(true);
        this.onSuccess('Paarid edukalt genereeritud');
      }else {
        this.onError('Üks settidest on tühi');
      }
    }else {
      this.onError('Väljad täitmata');
    }
  }
  updateQuestion(): void {
    this.questionService.getQuestions(this.selectedQuestionSet).subscribe(questions => this.questions = questions);
  }
  updateLocation(): void {
    this.locationService.getLocationsBySet(this.selectedLocationSet).subscribe(locations => this.locations = locations);
  }

  getPairSetId(): void{
    this.pairsetS.getAllPairsetsByRoomId(this.currentId).subscribe(respone => {
      this.pairSetId = respone[0].id;
      this.getCurrentPairs();
    });
  }
  getCurrentPairs(): void{
    this.pairS.getPairsByPairSetId(this.pairSetId).subscribe( pairs => {
      if (pairs){
        pairs.forEach(pair => {
          this.getPairDetails(pair.locationId, pair.questionId, pair.id);
        });
      }
    });
  }
  getPairDetails(locationId: number, questionId: number, pairId: number): void {
    this.locationService.getLocationsbyId(locationId).subscribe(location => {
      const coordinates = JSON.parse(location.location);
      const newCoordinates = 'pikkuskraad: ' + coordinates.lng +  ', laiuskraad: ' + coordinates.lat;
      this.questionService.getQuestionById(questionId).subscribe(question => {
        this.pairDetails.push(new PairDetail(pairId, newCoordinates, question.questionText));
      });
    });
  }

  deletePairs(): void {
    this.pairDetails.forEach(pair => {
      this.pairS.removePair(pair.pairId).subscribe();
    },
    () => {
      this.onError('Viga kustutamisel');
    });
    this.pairDetails = [];
  }

  getShowModal(): boolean {
    return this.modalService.getShowModal();
  }
}
