export const OAuthSettings = {
  // TalTech
  appId: '78637df6-bd9c-4013-9a4e-e935736bcf92',
  redirectUri: 'https://skoop.cs.taltech.ee/api/login/oauth2',
  tenantID: 'https://login.microsoftonline.com/3efd4d88-9b88-4fc9-b6c0-c7ca50f1db57',
  scopes: [
    'openid',
    'user.read',
  ]
};
// oauth
