<div class="row w-100 h-100">
  <div class="col-1">
    <div class="d-flex h-100">
      <div class="bg-light border-right h-100" id="sidebar-wrapper">
        <div id="mySidebar" class="sidebar" (mouseover)="toggleSidebar()" (mouseout)="toggleSidebar()">
          <a [routerLink]="['../', currentRoomId]">
            <i class="material-icons">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-house" viewBox="0 0 16 16">
                <path  fill-rule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
                <path  fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
              </svg>
            </i>
            <span class="icon-text"></span>Ruumi avaleht</a>

          <a *ngFor="let message of messages.sideMenu" [routerLink]="[message.routerLink]">
            <i class="material-icons" [innerHTML]="message.iconSvg | noSanitize"></i>
            <span class="icon-text"></span>{{message.title}}</a>

        </div>
      </div>
    </div>
  </div>

  <div class="col-10">
    <div id="main" class="text-center">
      <div *ngIf="router.url == '/roomediting/' + currentRoomId">
      <h2>Tere tulemast ruumi loomis lehele</h2>
      <div class="row">
        <div class="col-md-3 col-sm-6 mt-3" *ngFor="let message of messages.roomEditingIcons" [routerLink]="[message.routerLink]">
          <div class="card text-center" [ngClass]="{'active': tutorialService.getTutorialActive(), 'selected': message.routerLink == 'createlocation' ? tutorialService.getTutorialActive() : ''}">
            <div class="text-center iconSvg" [innerHTML]="message.iconSvg | noSanitize">
            </div>
            <h3 class="mt-3 mb-3">
              {{message.title}}
            </h3>
            <p class="card-text" [innerHTML]="message.subText | noSanitize"></p>
          </div>
        </div>

        <app-tutorial [modalTitle]="'Tere tulemast ruumi loomis lehele'"
                      [modalBody]="'<h3>Kuidas luua ruumi jaoks vajalikud komponendid.</h3>' +
               ' <p>Esmalt looge kaardistik.</p>'"
                      [showModal]="getShowModal()"
        ></app-tutorial>
      </div>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>



