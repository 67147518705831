<div class="container">
  <h1 class="mt-5">Kontakt</h1>
</div>
<div class="container contact-info">
  <div class="h-75 mt-5 row">
    <div class="col-md-12 col-lg-8">
      <div class="row ml-5 pt-3">
        <div class="col-xl-12">
          <h3>
            Telfon nr.
          </h3>
          <p>
            555 555 555
          </p>
          <h3>
            Aadress
          </h3>
          <p>
            Ehitajate tee 5
          </p>
          <h3>
            E-Mail
          </h3>
          <p>
            help@skoop.taltech.ee
          </p>
        </div>
      </div>
    </div>
    <div class="col-lg-4 d-none d-lg-block">
      <h2 class="mt-4">Küsimuste korral võtke julgelt meiega ühendust!</h2>
    </div>
  </div>
</div>
