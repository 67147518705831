import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BroadcastService, MsalService} from '@azure/msal-angular';
import {NavbarService} from '../../services/navbar.service';
import {OAuthService} from '../../services/o-auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  @ViewChild('navbarToggler') navbarToggler: ElementRef;

  constructor(private http: HttpClient,
              private msalService: MsalService,
              private broadcastService: BroadcastService,
              public nav: NavbarService,
              public authService: OAuthService) {
  }
  name: string;
  username: string;

  // test users data
  // name = 'Test Kasutaja';
  // username = 'test@kasutaja.com';

  createAccount: { userName: string; email: string };
  // testUser: Account;

  ngOnInit(): void {
    this.getProfile();
    this.broadcastService.subscribe('msal:loginSuccess', (success) => {
      this.getProfile();
    });
  }

  getProfile(): void {
    const account = this.msalService.getAccount();
    if (account !== null) {
      this.name = account.name;
      this.username = account.userName;
      this.getUserByEmailAndSaveToDb();
    } else {
      this.authService.setIsAuthenticated(false);
    }
  }

  signOut(): void {
    this.authService.setIsAuthenticated(false);
    this.msalService.logout();
  }

  async login(): Promise<void> {
    this.msalService.loginPopup();
  }

  navBarTogglerIsVisible(): boolean {
    return this.navbarToggler.nativeElement.offsetParent !== null;
  }

  collapseNav(): void {
    if (this.navBarTogglerIsVisible()) {
      this.navbarToggler.nativeElement.click();
    }
  }

  getUserByEmailAndSaveToDb(): void {
    this.authService.getAccountByEmail(this.username).subscribe(response => {
      if (response == null) {
        this.createAccount = {
          email: this.username,
          userName: this.name
        };
        this.authService.createAccount(this.createAccount).subscribe(res => {
          this.authService.setCurrentAccount(res);
        });
      } else {
        this.authService.getAccountByEmail(this.username).subscribe(res => {
          this.authService.setCurrentAccount(res);
        });
      }
      this.authService.setIsAuthenticated(true);
    });
  }
}
