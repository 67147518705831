import { PairSetGenComponent } from './components/pair-set-gen/pair-set-gen.component';
import { ManualPairGenComponent } from './components/manual-pair-gen/manual-pair-gen.component';
import { ShowActivatedRoomComponent } from './components/show-activated-room/show-activated-room.component';
import { LocationSetsComponent } from './components/location-sets/location-sets.component';
import { CreateMapComponent } from './components/create-map/create-map.component';
import { MapComponent } from './components/map/map.component';
import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {GreetingComponent} from './components/greeting/greeting.component';
import {QuestionsanswersComponent} from './components/questionsanswers/questionsanswers.component';
import {RoomComponent} from './components/room/room.component';
import {ContactComponent} from './components/contact/contact.component';
import {CreateNewRoomComponent} from './components/create-new-room/create-new-room.component';
import {GoPlayRoomsComponent} from './components/go-play-rooms/go-play-rooms.component';
import {UsersRoomsComponent} from './components/users-rooms/users-rooms.component';
import {QuestionSetComponent} from './components/question-set/question-set.component';
import {QuestionSetsComponent} from './components/question-sets/question-sets.component';
import {NotfoundComponent} from './components/notfound/notfound.component';
import {RoomEditingComponent} from './components/room-editing/room-editing.component';
import { ActivateRoomComponent } from './components/activate-room/activate-room.component';
import { GameMasterComponent } from './components/game-master/game-master.component';
import {ArchiveComponent} from './components/archive/archive.component';
import {ArchiveGameViewComponent} from './components/archive-game-view/archive-game-view.component';
import {MsalGuard} from '@azure/msal-angular';

const routes: Routes = [
  { path: '', redirectTo: '/avaleht', pathMatch: 'full'},
  { path: 'avaleht', component: GreetingComponent},
  { path: 'kkk', component: QuestionsanswersComponent, },
  { path: 'mang', component: RoomComponent},
  { path: 'mang/:joincode', component: GoPlayRoomsComponent},
  { path: 'mang/:joincode/:teamname', component: MapComponent},
  { path: 'kontakt', component: ContactComponent},
  { path: 'createmap', component: CreateMapComponent,  },
  { path: 'createlocation', component: LocationSetsComponent,  },
  { path: 'createlocation/:id', component: CreateMapComponent,  },
  { path: 'ruumid', component: UsersRoomsComponent, canActivate: [MsalGuard]},
  { path: 'tiimiRegamine', component: GoPlayRoomsComponent},
  { path: 'roomediting/:id', component: RoomEditingComponent, canActivate: [MsalGuard],
    children: [
      {
        path: 'ruumid',
        component: CreateNewRoomComponent
      },
      {
        path: 'ruumid/manual/:set',
        component: ManualPairGenComponent
      },
      {
        path: 'ruumid/pairset/:set',
        component: PairSetGenComponent
      },
      { path: 'aktiveeri',
        component: ActivateRoomComponent,
      },
      { path: 'aktiveeri/:activeroom',
        component: ShowActivatedRoomComponent,
      },
      { path: 'aktiveeri/:activeroom/ulevaade',
        component: GameMasterComponent,
      },
      {
        path: 'questionsets',
        component: QuestionSetsComponent
      },
      {
        path: 'createlocation',
        component: LocationSetsComponent
      },
      {
        path: 'questionsets/:id/:name',
        component: QuestionSetComponent
      },
      {
        path: 'arhiiv',
        component: ArchiveComponent
      },
      {
        path: 'arhiiv/:id/:gamename',
        component: ArchiveGameViewComponent
      }
    ]},
  { path: '**', component: NotfoundComponent,   }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
