<div class='container text-center'>
  <h2>
    Paaride genereerimine
  </h2>
  <div class="row" >
    <div *ngIf="preAssignedGamemode; else elseBlock" class="col-md-3 col-sm-6 mt-3" (click)="openModal(messages.preAssignedAction[0].openModal)">
      <div class="card text-center">
        <div class="text-center iconSvg" [innerHTML]="messages.preAssignedAction[0].iconSvg | noSanitize"></div>
        <h3 class="mt-3 mb-3">{{messages.preAssignedAction[0].title}}</h3>
        <p class="card-text">{{messages.preAssignedAction[0].subText}}</p>
      </div>
      </div>
    <ng-template #elseBlock>
      <div class="col-md-3 col-sm-6 mt-3" *ngFor="let message of messages.genActions" (click)="openModal(message.openModal)">
        <div class="card text-center" [ngClass]=" {'selected': message.openModal === 'autoGen' ? !tutorialService.getIfGenPairAuto() : ''}">
          <div class="text-center iconSvg" [innerHTML]="message.iconSvg | noSanitize"></div>
          <h3 class="mt-3 mb-3">{{message.title}}</h3>
          <p class="card-text">{{message.subText}}</p>
        </div>
      </div>
    </ng-template>

    <div class="col-md-3 col-sm-6 mt-3" *ngFor="let message of messages.pairActions" (click)="openModal(message.openModal)">
      <div class="card text-center" [ngClass]="{'selected': message.openModal === 'genPairs' ? !tutorialService.getIfLookedQuestions() && tutorialService.getIfGenPairAuto() : ''}">
        <div class="text-center iconSvg" [innerHTML]="message.iconSvg | noSanitize">
          <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" fill="currentColor" class="bi bi-question" viewBox="0 0 16 16">
            <path  d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
          </svg>
        </div>
        <h3 class="mt-3 mb-3">{{message.title}}</h3>
        <p class="card-text">{{message.subText}}</p>
      </div>
    </div>

    <simple-notifications></simple-notifications>
  </div>
</div>
<div class="row fixed-bottom text-center mx-auto">
  <div class="col">
    <button class="btn btn-info" routerLink="..">Tagasi</button>
  </div>
</div>

<jw-modal id="manualGen">
  <button type="button" class="close" (click)="resetForm()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class='container'>
  <div class="form-group space-up">
    <div class="row">
      <div class="col">
        <label for="locationsetsMap" class="font-weight-bold col-sm-2 col-form-label">Vali Kaardistik</label>
        <select id="locationsetsMap" [(ngModel)]="selectedMap" class="form-control">
          <option *ngFor="let c of locationSetdata" [ngValue]="c.id">{{c.name}}</option>
        </select>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col">
        <button (click)='goGenerate()' class="btn btn-primary float-left">Mine genereerima</button>
        <button class="btn btn-secondary float-right" (click)="closeModal('manualGen')">Sulge</button>
      </div>
    </div>
  </div>
</div>
</jw-modal>

<jw-modal id="manualPairSetGen">
  <button type="button" class="close" (click)="resetForm()" aria-label="Close">
    <span aria-hidden="true" (click)="closeModal('manualPairSetGen')">&times;</span>
  </button>
  <div class='container'>
  <div class="form-group space-up">
    <div class="row">
      <div class="col">
        <label for="locationsetsMap" class="font-weight-bold col-sm-2 col-form-label">Vali Kaardistik</label>
        <select id="locationsetsMap" [(ngModel)]="selectedMap" class="form-control">
          <option *ngFor="let c of locationSetdata" [ngValue]="c.id">{{c.name}}</option>
        </select>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col">
        <button (click)='goGeneratePairSets()' class="btn btn-primary float-left">Mine genereerima</button>
        <button class="btn btn-secondary float-right" (click)="closeModal('manualPairSetGen')">Sulge</button>
      </div>
    </div>
  </div>
</div>
</jw-modal>

<jw-modal id="autoGen">
  <button type="button" class="close" aria-label="Close" (click)="closeModal('autoGen')">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class='container'>
    <div class="form-group space-up">
      <div class="row">
        <div class="col">
          <label for="locationsets" class="font-weight-bold col-sm-2 col-form-label">Vali Kaardistik</label>
          <select id="locationsets" [(ngModel)]="selectedLocationSet" (change)="updateLocation()" class="form-control">
            <option *ngFor="let c of locationSetdata" [ngValue]="c.id">{{c.name}}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="questionsets" class="font-weight-bold col-sm-2 col-form-label">Vali Küsimustik</label>
          <select id="questionsets" [(ngModel)]="selectedQuestionSet" (change)="updateQuestion()" class="form-control">
            <option *ngFor="let c of questionSetdata" [ngValue]="c.id">{{c.name}}</option>
          </select>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col">
          <button class="btn btn-primary float-left" (click)="addPair(); closeModal('autoGen')">Genereeri automaatselt</button>
          <button class="btn btn-secondary float-right" (click)="closeModal('autoGen')">Sulge</button>
        </div>
      </div>
    </div>
  </div>
</jw-modal>

<jw-modal id="genPairs">
  <button type="button" class="close" aria-label="Close" (click)="closeModal('genPairs')">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class='container'>
    <div class="form-group space-up">
      <div class="row">
        <div class="col">
          <div class="container">
            <table class="table table-striped table-light overflow-auto">
              <thead>
              <tr>
                <th>Asukoht</th>
                <th>Küsimus<s></s></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let pairDetail of pairDetails; index as i">
                <td>{{pairDetail.location}}</td>
                <td>{{pairDetail.question}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">

        </div>
      </div>
      <div class="row pt-3">
        <div class="col">
          <button class="btn btn-danger float-left" (click)="deletePairs(); closeModal('genPairs')">Kustuta paarid</button>
          <button class="btn btn-secondary float-right" (click)="closeModal('genPairs'); tutorialService.setIfLookedQuestions(true)">Sulge</button>
        </div>
      </div>
    </div>
  </div>
</jw-modal>

<jw-modal id="areYouSureDelete">
  <div class="col-sm-12 col-md-12 col-lg-12 form text-center">
    <h2>Olete kindel kustutamast kõik paarid?</h2>
    <div class="modal-footer">
      <button type="submit" (click)="deletePairs(); closeModal('areYouSureDelete')" class="btn btn-modal btn-danger mx-auto">Kustuta</button>
      <button type="button" class="btn btn-modal btn-secondary mx-auto" (click)="closeModal('areYouSureDelete');">Sulge</button>
    </div>
  </div>
</jw-modal>


<app-tutorial *ngIf="!tutorialService.getIfGenPairAuto()"
              [modalTitle]="'Tere tulemast paaride loomis lehele'"
              [modalBody]="'<h3>Kuidas luua paare.</h3>' +
               ' <p>Vajutage  \'Genereeri automaatselt\' peale. Genereeritakse teile automaatselt küsimustiku paarid.</p>' +
               ' <p> Saate valida kaardi ja küsimustiku mis genereeritakse markeritele </p>'"
              [showModal]="getShowModal()">
</app-tutorial>

<app-tutorial *ngIf="tutorialService.getIfGenPairAuto() && !tutorialService.getIfLookedQuestions()"
              [modalTitle]="'Genereeritud küsimuste vaatamine'"
              [modalBody]="'<p>Vajutage  \'Vaata valmistatud küsimuste \' peale.</p>' +
                '<p>Näete mis küsimused on genereeritud ja kus asuvad need.</p>' +
                 '<p>Võimalusel on teil võimalik küsimuste paarid ära kustutada, aga ärme tee veel seda.</p>'"
              [showModal]="getShowModal()">
</app-tutorial>

<app-tutorial *ngIf="tutorialService.getIfLookedQuestions()"
              [modalTitle]="'Küsimused vaadatud'"
              [modalBody]="'<p>Suunduge küljemenüüst  \'Aktiveeri\' peale</p>'"
              [showModal]="getShowModal()">
</app-tutorial>
