import { PairsetsService } from '../../services/pairsets.service';
import { QuestionSetsService } from '../../services/question-sets.service';
import { QuestionService } from '../../services/question.service';
import { Questionset } from '../../interfaces/questionset';
import { PairService } from '../../services/pair.service';
import { BackControl } from '../create-map/back-control';
import { ModalService } from '../../services/modal.service';
import { Location } from '../../interfaces/location';
import { LocationService } from '../../services/location.service';
import { Component, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import Geolocation from 'ol/Geolocation';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import View from 'ol/View';
import {fromLonLat} from 'ol/proj.js';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import {Icon, Style} from 'ol/style';
import { ActivatedRoute } from '@angular/router';
import {Location as URL} from '@angular/common';
import {defaults as defaultControls} from 'ol/control';
import { FormGroup} from '@angular/forms';
import { Pair } from 'src/app/interfaces/pair';
import { Question } from 'src/app/interfaces/question';
import {OAuthService} from '../../services/o-auth.service';

interface LonAndLat {
  lat: number;
  lng: number;
}

@Component({
  selector: 'app-manual-pair-gen',
  templateUrl: './manual-pair-gen.component.html',
  styleUrls: ['./manual-pair-gen.component.css']
})
export class ManualPairGenComponent implements OnInit {

  @ViewChild('modal') modal: ElementRef;
  questionSetdata: Questionset[] = [];
  selectedQuestionSet;
  selectedQuestion;
  selectedLocation;
  selectedPair;
  questionText;
  questions: Question[] = [];
  form: FormGroup;
  editing = false;
  pngUrl;
  map;
  view;
  geolocation;
  player;
  vectorSource;
  vectorLayer: any;
  tileLayer: any;
  roomId;
  locationSetId = 0;
  pairSetId;
  locations: Location[] = [];
  markers = [];
  selectedFeature: Feature;
  listOfLocationIds = [];
  accuracyFeature; // GPS Tracker Radius
  constructor(private activatedRoute: ActivatedRoute,
              private modalService: ModalService,
              private urlLocation: URL,
              private pairService: PairService,
              private pairsetS: PairsetsService,
              private questionService: QuestionService,
              private questionSetsService: QuestionSetsService,
              private locationService: LocationService,
              private oAuthService: OAuthService
              ) {
                this.activatedRoute.parent.params.subscribe(
                  (params) => {
                    this.roomId = +params.id;
                  });
                this.locationSetId = +this.activatedRoute.snapshot.paramMap.get('set');
  }

  ngOnInit(): void {
    this.initializeMap();
    this.getPairSetId();
  }
  resetForm(): void{
    this.editing = false;
    this.closeModal('createPair');
  }
  resetPairForm(): void{
    this.selectedPair = undefined;
    this.questionText = undefined;
    this.editing = false;
    this.closeModal('editPair');
  }

  postPair(): void{
    if (this.selectedQuestionSet && this.selectedQuestion){
      const pairObj = {
        questionId: this.selectedQuestion,
        locationId: this.selectedLocation,
        pairSetId: this.pairSetId,
        disappearType: 'CORRECT',
      };
      this.pairService.postPair(pairObj).subscribe(respone => {
        this.selectedFeature.set('pairId', respone.id);
        this.selectedFeature.setStyle(new Style({
          image: new Icon(({
            crossOrigin: 'anonymous',
            src: './assets/mapIcons/pair.png',
            scale: 0.08,
          }))
        }));
        this.resetForm();
      });
    }else{
      alert('Täida väljad');
    }
  }
  updateQuestions(): void{
    this.questionService.getQuestions(this.selectedQuestionSet).subscribe(questions => {
      this.questions = questions;
    });
  }
  createPair(): void{
    this.questionSetsService.getQuestionSetsByUserId(this.oAuthService.getCurrentAccount().id).subscribe( sets => {
      this.questionSetdata = sets;
      this.openModal('createPair');
    });
  }

  editPair(): void{
    this.pairService.getPairbyId(this.selectedPair).subscribe(pair => {
      this.questionService.getQuestionById(pair.questionId).subscribe(question => {
        this.questionText = question.questionText;
        this.openModal('editPair');
      });
    });
  }
  deletePair(): void{
    this.pairService.removePair(this.selectedPair).subscribe( () => {
      this.selectedFeature.set('pairId', undefined);
      this.selectedFeature.setStyle(new Style({
        image: new Icon(({
          crossOrigin: 'anonymous',
          src: './assets/mapIcons/place.png',
          scale: 0.08,
        }))
      }));
      this.resetPairForm();
    });
  }
  getPairSetId(): void{
    this.pairsetS.getAllPairsetsByRoomId(this.roomId).subscribe(respone => {
      this.pairSetId = respone[0].id;
      this.getAllPairs();
    });
  }

  getAllPairs(): void{
    this.pairService.getPairsByPairSetId(this.pairSetId).subscribe( pairs => {
      if (pairs) {
        pairs.forEach(pair => {
          this.listOfLocationIds.push(pair.locationId);
          this.addNewPairLocation(pair);
        });
      }
      this.getAllLocations();
    });
  }

  getAllLocations(): void{
    this.locationService.getLocationsBySet(this.locationSetId).subscribe(locations =>
      locations.forEach(location => {
        if (!this.listOfLocationIds.includes(location.id)){
          this.addNewLocation(location);
        }
      }));
  }
  addNewPairLocation(pair: Pair): void{
    this.locationService.getLocationsbyId(pair.locationId).subscribe(location => {
      const coordinates: LonAndLat = JSON.parse(location.location);
      this.createMarekrs(coordinates.lng, coordinates.lat, location.id, pair.id);
    });
  }

  addNewLocation(location: Location): void{
    const coordinates: LonAndLat = JSON.parse(location.location);
    this.createMarekrs(coordinates.lng, coordinates.lat, location.id, null);
  }

  getlocation(): void{
    this.accuracyFeature = new Feature();
    this.geolocation.on('change:accuracyGeometry', () => {
    this.accuracyFeature.setGeometry(this.geolocation.getAccuracyGeometry());
    });
    this.accuracyFeature.setId(-1);

    this.vectorSource.addFeature(this.accuracyFeature);

    this.geolocation.setTracking(true);

    this.geolocation.on('change:position', () => {
      const coordinates = this.geolocation.getPosition();
      this.player.setGeometry(coordinates ? new Point(coordinates) : null);
      this.map.getView().setCenter(fromLonLat(coordinates, 'EPSG:4326', 'EPSG:3857'));
      this.geolocation.setTracking(false);
    });
  }
  createMarekrs(Lon, Lat, Id, pairId): Feature {
    this.pngUrl = './assets/mapIcons/place.png';
    const marker = new Feature({
      geometry: new Point(fromLonLat([Lon, Lat])),
      name: 'marker'
    });
    if (pairId){
      this.pngUrl = './assets/mapIcons/pair.png';
      marker.set('pairId', pairId);
    }
    marker.setStyle(new Style({
      image: new Icon(({
        crossOrigin: 'anonymous',
        src: this.pngUrl,
        scale: 0.08,
      }))
    }));
    marker.setId(Id);
    this.vectorSource.addFeature(marker);
  }

  openModal(id: string): void {
    this.modalService.open(id);
  }
  closeModal(id: string): void {
    this.modalService.close(id);
  }

  private initializeMap(): void{
    this.player = new Feature({
      geometry: new Point(fromLonLat([24.6675, 59.39503])),
      name: 'player'
    });
    this.player.setStyle(new Style({
      image: new Icon(({
        crossOrigin: 'anonymous',
        src: 'assets/mapIcons/arrow.png',
        scale: 0.08,
      }))
    }));
    this.player.setId(0);
    this.markers = [this.player];
    this.vectorSource = new VectorSource({
      features: this.markers
    });
    this.vectorLayer = new VectorLayer({
      source: this.vectorSource
    });
    this.tileLayer = new TileLayer({
      source: new OSM()
    });

    this.view = new View({
      center: fromLonLat([24.6675, 59.39503]),
      zoom: 16
    });

    this.map = new Map({
      controls: defaultControls().extend([new BackControl(this.urlLocation)]),
      target: 'map',
      layers: [this.tileLayer, this.vectorLayer],
      view: this.view,
    });
    this.map.set('customMode', 'add');

    this.geolocation = new Geolocation({
      trackingOptions: {
        enableHighAccuracy: true,
      },
      projection: this.view.getProjection(),
    });

    this.getlocation();

    this.map.on('click', (data) => {
        this.map.forEachFeatureAtPixel(data.pixel, (feature) => {
          if (feature.getId() >= 1 && !this.editing){
            this.editing = true;
            this.selectedLocation = feature.getId();
            this.selectedFeature = feature;
            if (feature.get('pairId')){
              this.selectedPair = feature.get('pairId');
              this.editPair();
            }else{
              this.createPair();
            }
          }
        });
    });
  }
}
