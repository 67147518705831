import { RoomService } from './../../services/room.service';
import { PairsetsService } from './../../services/pairsets.service';
import { VisitedPointsService } from './../../services/visited-points.service';
import { Team } from '../../interfaces/team';
import { TeamService } from './../../services/team.service';
import { ModalService } from './../../services/modal.service';
import { MultipleChoice } from './../../questionTypes/multliple_Choice';
import { CheckBox } from './../../questionTypes/checkBox';
import { ActiveRoomsService } from './../../services/active-rooms.service';
import { GPSControl } from './gps-control';
import { Observable, Subscription } from 'rxjs';
import { Question } from '../../interfaces/question';
import { QuestionService } from './../../services/question.service';
import { PairService } from './../../services/pair.service';
import { Component, HostListener, Inject, OnInit, OnDestroy } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Location } from '../../interfaces/location';
import { LocationService } from './../../services/location.service';
import { FormBuilder, FormGroup} from '@angular/forms';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import Geolocation from 'ol/Geolocation';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import View from 'ol/View';
import {fromLonLat} from 'ol/proj.js';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import {Icon, Style} from 'ol/style';
import { ActivatedRoute } from '@angular/router';
import {defaults as defaultControls} from 'ol/control';
import {NavbarService} from '../../services/navbar.service';
import { ActiveRoom } from '../../interfaces/activeroom';
import { Text } from 'src/app/questionTypes/text';
import {DOCUMENT} from '@angular/common';

declare let $: any;

// Interface to help with saving marker coordinates
interface LonAndLat {
  lat: number;
  lng: number;
}
export interface Answer {
  text: string;
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})

export class MapComponent implements OnInit, OnDestroy {
  @ViewChild('modal') modal: ElementRef;
  joinCode;
  activeRoom: ActiveRoom;
  form: FormGroup;
  questionobj: Question;
  teamName;
  team: Team;
  currentGamePairIds = [];
  disabledSelect = true;
  // Question Types -------------
  textQuestion: Text;
  checkBoxQuestion: CheckBox;
  multipleChoiceQ: MultipleChoice;
  //  Question Parameters
  type;
  question;
  answer;
  answers: string[] = [];
  choices: string[] = [];
  hint;
  points;
  questionText;
  pointsTrue;
  pointsFalse;
  // -----
  // Question Answers
  gameMode;
  selectedAnswer = null;
  selectedAnswers;
  textAnswer;
  answerCorrect: boolean;
  map;
  player;
  vectorSource;
  vectorLayer: any;
  tileLayer: any;
  answering = false;
  score = 0;
  questionsLeft = 0;
  geolocation;
  view;
  currentPairId;
  accuracyFeature; // GPS Tracker Radius
  questions$: Observable<Question[]>;
  // --Timer-- //
  seconds;
  minutes;
  hours;
  interval;
  elem;
  isFullScreen = false;
  private subscriptions: Subscription[] = [];

  constructor(
    public acService: ActiveRoomsService,
    public roomService: RoomService,
    public pairsetS: PairsetsService,
    public vPointsService: VisitedPointsService,
    public teamService: TeamService,
    public modalService: ModalService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private pairService: PairService,
    private locationService: LocationService,
    private questionService: QuestionService,
    public nav: NavbarService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.form = this.formBuilder.group({
      answers: ['']
    });

  }
  ngOnInit(): void {
    this.elem = document.documentElement;
    const subRoute = this.activatedRoute.params.subscribe(
      (params) => {
        this.joinCode = params.joincode;
        this.teamName = params.teamname;
        const subRoom = this.acService.getActiveGameByJoinCode(this.joinCode).subscribe(room => {
          this.activeRoom = room;
          this.getGamemode();
        });
        const subTeam = this.teamService.getTeamByName(this.teamName).subscribe(team => {
          this.team = team;
          this.setTimer();
          this.getPairs();
        });
        this.subscriptions.push(subRoom);
        this.subscriptions.push(subTeam);
      });
    this.subscriptions.push(subRoute);
    this.initializeMap();
    this.getPlayerLocation();
  }

  ngOnDestroy(): void {
    this.geolocation.setTracking(false);
    clearInterval(this.interval);
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  setTimer(): void {
    if (this.team.startTime){
      this.seconds = (+new Date(this.team.gameEndTime) - +Date.now()) / 1000;
      if ((this.seconds) <= 0){
        this.seconds = 0;
      }
      this.activateTimer();
    }else{
      const sub = this.teamService.setStartAndGameEndTime(this.team.id).subscribe( response => {
        this.seconds = (+new Date(response.gameEndTime) - +Date.now()) / 1000;
        this.activateTimer();
      });
      this.subscriptions.push(sub);
    }
  }
  setTeamFinishTime(): void{
    const sub = this.teamService.setTeamFinishTime(this.team.id).subscribe();
    this.subscriptions.push(sub);
  }
  getGamemode(): void{
    const sub = this.roomService.getRoomById(this.activeRoom.roomId).subscribe(room => this.gameMode = room.gameMode);
    this.subscriptions.push(sub);
  }

  checkForVisitedPoints(): void{
    const sub = this.vPointsService.getVisitedPointsByActiveRoomId(this.activeRoom.id).subscribe(response => {
      if (response){
        response.forEach(pair => {
          if (this.currentGamePairIds.includes(pair.pairId)){
            this.currentGamePairIds.map( item => {
                delete pair.id;
                return item;
              }
            );
            this.removeMarkerFromMap(pair.pairId);
          }
        });
        this.checkRadius();
      }else{
        this.checkRadius();
      }
    });
    this.subscriptions.push(sub);
  }

  removeMarkerFromMap(pairId: number): void{
    const features = this.vectorLayer.getSource().getFeatures();
    features.forEach(element => {
      if (element.get('PairId') === pairId){
        this.vectorSource.removeFeature(element);
        this.questionsLeft--;
        return;
      }
    });
  }

  activateTimer(): void{
    this.hours = Math.floor(this.seconds / 3600);
    this.minutes = Math.floor(this.seconds % 3600 / 60);
    this.seconds = Math.floor(this.seconds % 3600 % 60);
    this.startTimer();
  }
  startTimer(): void{
    this.interval = setInterval(() => {
      // fix to rush mode when there is no markers left
      if (this.questionsLeft === 0) {
        this.modalService.open('gameEnded');
      }
      if (this.seconds <= 0 && this.minutes <= 0 && this.hours <= 0){
        this.questionsLeft = 0;
        this.answering = true;
        this.setTeamFinishTime();
        this.modalService.open('gameEnded');
      }else if (this.minutes <= 0 && this.hours > 0){
        this.minutes = 59;
        this.hours--;
      }else if (this.seconds <= 0 && this.minutes > 0){
        this.seconds = 59;
        this.minutes--;
      }else if (this.seconds > 0) {
        this.seconds--;
      }
    }, 1000);
  }

  // Checks if there are any markers close enough to the player
  public checkRadius(): void{
    const features = this.vectorLayer.getSource().getFeatures();
    features.forEach(element => {
      const id = element.getId();
      if (id !== undefined && id !== -1 && !this.answering) {
        const player = this.player.getGeometry().getCoordinates();
        const point = element.getGeometry().getClosestPoint(this.player.getGeometry().getCoordinates());
        const xdif = Math.pow(player[0] - point[0], 2);
        const ydif = Math.pow(player[1] - point[1], 2);
        const distance = Math.pow(xdif + ydif, 0.5);
        if (distance <= element.get('Radius')){
          this.answering = true;
          this.currentPairId = element.get('PairId');
          this.getQuestion(element.get('QuestionId'), element);
        }
        }
    });

  }

  // Checks if current questions answer is correct
  public checkanswer(type: string): void{
    if (this.questionsLeft === 0){
      this.setTeamFinishTime();
      this.modalService.open('gameEnded');
    }
    console.log(this.selectedAnswer);
    switch (type) {
      case 'CHECKBOX': {
        this.modalService.close('checkBoxQuestion');
        const checker = (arr, target) => target.every(v => arr.includes(v));
        if (checker(this.selectedAnswers, this.answers) && (this.selectedAnswers.length === this.answers.length)) {
          this.score += this.pointsTrue;
          this.modalService.open('correctAnswer');
          this.answerCorrect = true;
        }else {
          this.score += this.pointsFalse;
          this.modalService.open('wrongAnswerCheckBox');
          this.answerCorrect = false;
        }
        break;
      }
      case 'TEXT': {
        this.modalService.close('textQuestion');
        if (this.textAnswer.trim() === this.answer.trim()) {
          this.score += this.pointsTrue;
          this.modalService.open('correctAnswer');
          this.answerCorrect = true;
        }else{
          this.score += this.pointsFalse;
          this.modalService.open('wrongAnswerText');
          this.answerCorrect = false;
        }
        break;
      }case 'MULTIPLE_CHOICE': {
        this.modalService.close('multipleChoiceQuestion');
        if (this.selectedAnswer === this.answer){
          this.score += this.pointsTrue;
          this.modalService.open('correctAnswer');
          this.answerCorrect = true;
        }else{
          this.score += this.pointsFalse;
          this.modalService.open('wrongAnswerMultipleChoice');
          this.answerCorrect = false;
        }
        break;
      }
    }
    const teamObj = {
      id: this.team.id,
      score: this.score
    };
    const visitedPointObj = {
      correct: this.answerCorrect,
      pairId: this.currentPairId,
      teamId: this.team.id
    };
    const subTeam = this.teamService.setDetailsTeam(teamObj).subscribe(() => {
      const subVP = this.vPointsService.createVisitedPoint(visitedPointObj).subscribe(() => {
        this.answering = false;
      });
      this.subscriptions.push(subVP);
    });
    this.subscriptions.push(subTeam);
  }
  resetAnswers(): void{
    this.selectedAnswer = null;
    this.selectedAnswers = [];
    this.textAnswer = '';
    this.answer = '';
    this.answers = [];
  }
  // Posts current team location
  saveTeamLocation(coordinates): void{
    if (this.team){
      const teamObj = {
        id: this.team.id,
        location: coordinates[0] + ' ; ' + coordinates[1]
    };
      const subLocation = this.teamService.updateLocation(teamObj).subscribe();
      this.subscriptions.push(subLocation);
    }
  }

  // Get current players location and sets it as map center
  getPlayerLocation(): void{
    this.accuracyFeature = new Feature();
    this.geolocation.on('change:accuracyGeometry', () => {
    this.accuracyFeature.setGeometry(this.geolocation.getAccuracyGeometry());
    });
    this.accuracyFeature.setId(-1);

    this.vectorSource.addFeature(this.accuracyFeature);

    this.geolocation.setTracking(true);

    this.geolocation.on('change:position', () => {
      const coordinates = this.geolocation.getPosition();
      this.player.setGeometry(coordinates ? new Point(coordinates) : null);
      if (this.map.get('GPSMode') === 'on'){
        this.map.getView().setCenter(fromLonLat(coordinates, 'EPSG:4326', 'EPSG:3857'));
      }
      if (!this.answering){
        this.saveTeamLocation(coordinates);
        if (this.gameMode === 'RUSH'){
          this.checkForVisitedPoints();
        }else{
          this.checkRadius();
        }
      }
    });
  }

  // Gets all question and location pairs of the current game
  getPairs(): void{
    const subPair = this.pairService.getPairsByPairSetId(this.team.pairSetId).subscribe(pairs => {
      if (pairs){
        pairs.forEach(pair => {
          this.currentGamePairIds.push(pair.id);
          const subLocation = this.locationService.getLocationsbyId(pair.locationId).subscribe(location =>
              this.addNewLocation(location, pair.questionId, pair.id)
            );
          this.subscriptions.push(subLocation);
        });
        if (this.gameMode === 'RUSH'){
          this.checkForVisitedPoints();
        }
      }
    });
    this.subscriptions.push(subPair);
  }

  // Get current markers question
  getQuestion(id: number, feature: Feature): void{
    const subQuestion = this.questionService.getQuestionById(id).subscribe(question => {
      this.questionobj = question;
      this.showQuestion(feature, question);
    });
    this.subscriptions.push(subQuestion);
  }
  // Closes all modals if any is open
  closeAllModals(): void{
    this.modalService.close('correctAnswer');
    this.modalService.close('wrongAnswerCheckBox');
    this.modalService.close('wrongAnswerText');
    this.modalService.close('wrongAnswerMultipleChoice');
  }

  // Saves all current question data and shows question on the map
  showQuestion(feature: Feature, question): void{
    if (this.questionobj !== undefined) {
      this.resetAnswers();
      this.closeAllModals();
      this.type = question.type;
      this.questionText = question.questionText;
      this.pointsFalse = question.pointsFalse;
      this.pointsTrue = question.pointsTrue;
      switch (this.type) {
        case 'CHECKBOX': {
          this.choices = [];
          question.choices.forEach(q => {
          this.choices.push(q);
           });
          question.answer.forEach(q => {
          this.answers.push(q);
            });
          this.modalService.open('checkBoxQuestion');
          break;
        }
        case 'TEXT': {
          this.hint = question.hint;
          this.answer = question.answer;
          this.modalService.open('textQuestion');
          break;
        }case 'MULTIPLE_CHOICE': {
          this.choices = [];
          this.answer = question.answer;
          question.choices.forEach(q => {
          this.choices.push(q);
           });
          this.modalService.open('multipleChoiceQuestion');
          break;
        }
      }
      this.questionsLeft--;
      this.vectorSource.removeFeature(feature);
    }
  }

  // Function the gets correct coordinates of marker and calls helper function to add them to the map
  addNewLocation(location: Location, qID: number, pId: number): void{
    this.questionsLeft++;
    const coordinates: LonAndLat = JSON.parse(location.location);
    this.createMarekrs(coordinates.lng, coordinates.lat, location, qID, pId);
  }

  // Helper function that adds markers on the map
  createMarekrs(Lon, Lat, location: Location, qID, pId): Feature{
    const marker = new Feature({
      geometry: new Point(fromLonLat([Lon, Lat])),
      name: 'marker'
    });
    marker.setStyle(new Style({
      image: new Icon(({
        crossOrigin: 'anonymous',
        src: './assets/mapIcons/place.png',
        scale: 0.08,
      }))
    }));
    marker.setId(location.id);
    marker.set('QuestionId', qID);
    marker.set('PairId', pId);
    marker.set('Radius', location.coverRadius);
    this.vectorSource.addFeature(marker);
    this.checkRadius();
  }


  openModal(id: string, element: Question): void {
    this.modalService.open(id);
  }
  closeModal(id: string): void {
    this.modalService.close(id);
  }

  // Updates answers when question type is checkbox
  onChange(answer, isChecked: boolean): void{
    if (isChecked) {
      this.selectedAnswers.push(answer);
    } else {
      const index = this.selectedAnswers.indexOf(answer);
      this.selectedAnswers.splice(index, 1);
    }
}

  // Builds map and adds all required components to make the map work
  private initializeMap(): void {
    this.player = new Feature({
      geometry: new Point(fromLonLat([24.6675, 59.39503])),
      name: 'player'
    });
    this.player.setStyle(new Style({
      image: new Icon(({
        crossOrigin: 'anonymous',
        src: 'assets/mapIcons/arrow.png',
        scale: 0.08,
      }))
    }));

    this.vectorSource = new VectorSource({
      features: [this.player]
    });
    this.vectorLayer = new VectorLayer({
      source: this.vectorSource
    });
    this.tileLayer = new TileLayer({
      source: new OSM()
    });
    this.view = new View({
      center: fromLonLat([24.6675, 59.39503]),
      zoom: 16
    });

    this.map = new Map({
      target: 'map',
      layers: [this.tileLayer, this.vectorLayer],
      view: this.view,
      controls: defaultControls().extend([new GPSControl()]),
    });
    this.map.set('GPSMode', 'on');

    this.geolocation = new Geolocation({
      trackingOptions: {
        enableHighAccuracy: true,
      },
      projection: this.view.getProjection(),
    });
  }

  toggleFullScreen(): void {
    if (this.isFullScreen) {
      this.closeFullscreen();
      this.isFullScreen = false;
    } else {
      this.openFullscreen();
      this.isFullScreen = true;
    }
  }

  openFullscreen(): void {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }

  /* Close fullscreen */
  closeFullscreen(): void {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }
}
