import { Room } from '../interfaces/room';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

@Injectable({
  providedIn: 'root'
})
export class RoomService {
  private roomsUrl = 'api/rooms';
  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  constructor(private http: HttpClient) { }
  getRooms(): Observable<Room[]> {
    return this.http.get<Room[]>(this.roomsUrl);
  }
  getRoomById(id: number): Observable<Room> {
    return this.http.get<Room>(this.roomsUrl + '/' + id);
  }
  getRoomIdByName(name: string): Observable<number> {
    return this.http.get<number>(this.roomsUrl + '/name/' + name);
  }
  removeRoom(id: number): Observable<Room>{
    return this.http.delete<Room>(this.roomsUrl + '/' + id);
  }

  getRoomsByCreatorId(id: number): Observable<Room[]> {
    return this.http.get<Room[]>(this.roomsUrl + '/creator/' + id);
  }
  postRoom(roomObj): Observable<Room> {
    return this.http.post<Room>(this.roomsUrl, roomObj, {});
    }

}
