import { PairSetGenComponent } from './components/pair-set-gen/pair-set-gen.component';
import { MatListModule } from '@angular/material/list';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QuestionsanswersComponent } from './components/questionsanswers/questionsanswers.component';
import { GreetingComponent } from './components/greeting/greeting.component';
import { ContactComponent } from './components/contact/contact.component';
import { RoomComponent } from './components/room/room.component';
import { MapComponent } from './components/map/map.component';
import { CreateNewRoomComponent } from './components/create-new-room/create-new-room.component';
import {FormsModule} from '@angular/forms';
import {GoPlayRoomsComponent} from './components/go-play-rooms/go-play-rooms.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UsersRoomsComponent } from './components/users-rooms/users-rooms.component';
import { QuestionSetComponent } from './components/question-set/question-set.component';
import {MatTableModule} from '@angular/material/table';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import { CreateMapComponent } from './components/create-map/create-map.component';
import { LocationSetsComponent } from './components/location-sets/location-sets.component';
import { QuestionSetsComponent } from './components/question-sets/question-sets.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { NotfoundComponent } from './components/notfound/notfound.component';
import {LoadingScreenService} from './services/loading-screen.service';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import {LoaderInterceptorService} from './services/loader-interceptor.service';
import { SearchFilterPipe } from './filter/search-filter.pipe';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { RoomEditingComponent } from './components/room-editing/room-editing.component';
import { ActivateRoomComponent } from './components/activate-room/activate-room.component';
import { ShowActivatedRoomComponent } from './components/show-activated-room/show-activated-room.component';
import { ModalComponent } from './components/modal/modal.component';
import { TutorialComponent } from './components/tutorial/tutorial.component';
import { ManualPairGenComponent } from './components/manual-pair-gen/manual-pair-gen.component';
import {GameMasterComponent} from './components/game-master/game-master.component';
import {ArchiveComponent} from './components/archive/archive.component';
import {ArchiveGameViewComponent} from './components/archive-game-view/archive-game-view.component';
import { NoSanitizePipe } from './filter/no-sanitize.pipe';

import {
  MSAL_CONFIG, MSAL_CONFIG_ANGULAR,
  MsalService, MsalModule, MsalAngularConfiguration
} from '@azure/msal-angular';
import {OAuthSettings} from './components/oauth/oauth';
import {Configuration} from 'msal';


export const protectedResourceMap: [string, string[]][] = [
  ['https://graph.microsoft.com/beta/', ['user.read']]
];

function MSALConfigFactory(): Configuration {
  return {
    auth: {
      clientId: OAuthSettings.appId,
      authority: OAuthSettings.tenantID,
      validateAuthority: true,
      redirectUri: OAuthSettings.redirectUri,
      postLogoutRedirectUri: 'https://skoop.cs.taltech.ee/avaleht',
      navigateToLoginRequestUrl: true,
    },
    cache: {
      storeAuthStateInCookie: false,
    }
  };
}

function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return {
    popUp: true,
    protectedResourceMap
  };
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    QuestionsanswersComponent,
    GreetingComponent,
    RoomComponent,
    ContactComponent,
    CreateNewRoomComponent,
    MapComponent,
    GoPlayRoomsComponent,
    UsersRoomsComponent,
    CreateMapComponent,
    QuestionSetComponent,
    LocationSetsComponent,
    QuestionSetsComponent,
    NotfoundComponent,
    LoadingScreenComponent,
    SplashScreenComponent,
    RoomEditingComponent,
    SearchFilterPipe,
    ModalComponent,
    ActivateRoomComponent,
    ShowActivatedRoomComponent,
    TutorialComponent,
    ManualPairGenComponent,
    GameMasterComponent,
    PairSetGenComponent,
    ArchiveComponent,
    ArchiveGameViewComponent,
    NoSanitizePipe
  ],
    imports: [
      BrowserModule,
      HttpClientModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      FormsModule,
      ReactiveFormsModule,
      MatTableModule,
      MatFormFieldModule,
      MatListModule,
      MatInputModule,
      MatChipsModule,
      MatIconModule,
      CommonModule,
      SimpleNotificationsModule.forRoot(),
      MsalModule
    ],
  providers: [
    {
      provide: MSAL_CONFIG,
      useFactory: MSALConfigFactory
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MSALAngularConfigFactory
    },
    MsalService,
    LoadingScreenService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
