import { PairsetsService } from './../../services/pairsets.service';
import { VisitedPointsService } from './../../services/visited-points.service';
import { Team } from '../../interfaces/team';
import { TeamService } from './../../services/team.service';
import { ModalService } from './../../services/modal.service';
import { MultipleChoice } from './../../questionTypes/multliple_Choice';
import { CheckBox } from './../../questionTypes/checkBox';
import { ActiveRoomsService } from './../../services/active-rooms.service';
import { Observable } from 'rxjs';
import { Question } from '../../interfaces/question';
import { QuestionService } from './../../services/question.service';
import { PairService } from './../../services/pair.service';
import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Location } from '../../interfaces/location';
import { LocationService } from './../../services/location.service';
import { FormBuilder, FormGroup} from '@angular/forms';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import Geolocation from 'ol/Geolocation';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import View from 'ol/View';
import {fromLonLat} from 'ol/proj.js';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import {Circle as CircleStyle, Fill, Stroke, Icon, Style} from 'ol/style';
import { ActivatedRoute } from '@angular/router';
import {NavbarService} from '../../services/navbar.service';
import { ActiveRoom } from '../../interfaces/activeroom';
import { Text } from 'src/app/questionTypes/text';
import { BackControl } from './../create-map/back-control';
import {FullScreen, defaults as defaultControls} from 'ol/control';
import {Location as URL} from '@angular/common';


// Interface to help with saving marker coordinates
interface LonAndLat {
  lat: number;
  lng: number;
}
export interface Answer {
  text: string;
}

@Component({
  selector: 'app-game-master',
  templateUrl: './game-master.component.html',
  styleUrls: ['./game-master.component.css']
})
export class GameMasterComponent implements OnInit, OnDestroy {

  @ViewChild('modal') modal: ElementRef;
  joinCode;
  activeRoom: ActiveRoom;
  gameId;
  form: FormGroup;
  questionobj: Question;
  team: Team;
  // Question Types
  textQuestion: Text;
  checkBoxQuestion: CheckBox;
  multipleChoiceQ: MultipleChoice;
  //  Question Parameters
  type;
  question;
  choices: string[] = [];
  hint;
  questionText;
  // Question Answers
  map;
  vectorSource;
  vectorLayer: any;
  tileLayer: any;
  quizopen = true;
  geolocation;
  currentL: Location;
  view;
  pairSetIds = [];
  currentPairId;
  activeId;
  teams: Team[] = [];

  questions$: Observable<Question[]>;

  innerWidth: any;

  constructor(
    public acService: ActiveRoomsService,
    public pairsetS: PairsetsService,
    public vPointsService: VisitedPointsService,
    public teamService: TeamService,
    public modalService: ModalService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private pairService: PairService,
    private locationService: LocationService,
    private questionService: QuestionService,
    private urlLocation: URL,
    public nav: NavbarService
  ) {
    this.form = this.formBuilder.group({
      answers: ['']
    });

  }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      (params) => {
        this.activeId = params.activeroom;
      });
    this.acService.getActiveGameById(this.activeId).subscribe(room => {
      this.activeRoom = room;
      this.gameId = this.activeRoom.roomId;
      this.getTeams();
      this.getPairSetId();
    });
    this.innerWidth = window.innerWidth;
    this.initializeMap();
    this.updateMap();
  }

  sortTeams(): void{
    if (this.teams){
      this.teams.sort((a, b) => (a.score < b.score) ? 1 : -1);
    }
  }

  ngOnDestroy(): void {
    this.geolocation.setTracking(false);
  }

  updateTeamList(): void{
    this.teamService.getAllTeamsByActiveRoomId(this.activeId).subscribe(newTeams => {
      if (newTeams){
        newTeams.forEach(newTeam => {
          if (this.teams.some(team => team.id === newTeam.id &&
            (team.score !== newTeam.score || team.location !== newTeam.location))){
            this.updateTeamMarkeronMap(newTeam);
          }else if (!this.teams.some(team => team.id === newTeam.id)){
            this.teams.push(newTeam);
            this.createTeamMarker(newTeam);
            this.sortTeams();
          }
        });
      }
    });
  }

  updateTeamMarkeronMap(newTeam: Team): void{
    const index = this.teams.findIndex(team => team.id === newTeam.id);
    this.teams[index] = newTeam;
    this.sortTeams();
    const features = this.vectorLayer.getSource().getFeatures();
    features.forEach(element => {
      if (element.get('TeamId') === newTeam.id){
        const splitted = newTeam.location.split(';');
        element.setGeometry([+splitted[0], +splitted[1]] ? new Point([+splitted[0], +splitted[1]]) : null);
        return;
      }
    });
  }

  createTeamMarker(team: Team): void{
    const splitted = team.location.split(';');
    // tslint:disable-next-line: no-bitwise
    const randomColor = '#' + (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
    const marker = new Feature({
      geometry: ([+splitted[0], +splitted[1]] ? new Point([+splitted[0], +splitted[1]]) : null),
      name: 'marker'
    });
    marker.setStyle(new Style({
      image: new CircleStyle(({
      radius: 12,
      fill: new Fill({
        color: randomColor,
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 2,
      }),
      }))
    }));
    marker.set('TeamId', team.id);
    this.vectorSource.addFeature(marker);
  }

  centerView(team: Team): void{
    if (team.location){
      const splitted = team.location.split(';');
      this.map.getView().setCenter(fromLonLat([+splitted[0], +splitted[1]], 'EPSG:4326', 'EPSG:3857'));
    }
  }

  getTeams(): void {
    this.teamService.getAllTeamsByActiveRoomId(this.activeId).subscribe(teams => {
      if (teams){
        this.teams = teams;
        this.teams.forEach(team => {
          this.createTeamMarker(team);
        });
      }
    });
  }

  // Get current players location and sets it as map center
  getPlayerLocation(): void{
    this.geolocation.setTracking(true);
    this.geolocation.on('change:position', () => {
      const coordinates = this.geolocation.getPosition();
      this.updateTeamList();
    });
  }
  // Get current PairSetId
  getPairSetId(): void{
    this.pairsetS.getAllOriginalPairsetsByRoomId(this.gameId).subscribe(respone => {
      if (respone){
        respone.forEach( set => {
          this.pairSetIds.push(set.id);
        });
        this.getPairs();
      }
    });
  }

  // Gets all question and location pairs of the current game
  getPairs(): void{
    const pairIds = [];
    this.pairSetIds.forEach(id => {
      this.pairService.getPairsByPairSetId(id).subscribe(pairs =>
        { if (pairs){
            pairs.forEach(pair => {
              if (!pairIds.includes(pair.id)){
                pairIds.push(pair.id);
                this.locationService.getLocationsbyId(pair.locationId).subscribe(location =>
                  this.addNewLocation(location, pair.questionId, pair.id)
                );
              }
            });
          }
        });
    });
  }

  // Get current markers question
  getQuestion(id: number, feature: Feature): void{
    this.questionService.getQuestionById(id).subscribe(question => {
      this.questionobj = question;
      this.showQuestion(feature, question);
    }
      );
  }

  // Saves all current question data and shows question on the map
  showQuestion(feature: Feature, question): void{
    if (this.questionobj !== undefined) {
      this.type = question.type;
      this.questionText = question.questionText;
      switch (this.type) {
        case 'CHECKBOX': {
          this.choices = [];
          question.choices.forEach(q => {
          this.choices.push(q);
           });
          this.modalService.open('checkBoxQuestion');
          break;
        }
        case 'TEXT': {
          this.hint = question.hint;
          this.modalService.open('textQuestion');
          break;
        }case 'MULTIPLE_CHOICE': {
          this.choices = [];
          question.choices.forEach(q => {
          this.choices.push(q);
           });
          this.modalService.open('multipleChoiceQuestion');
          break;
        }
      }
    }
  }

  // Function the gets correct coordinates of marker and calls helper function to add them to the map
  addNewLocation(location: Location, qID: number, pId: number): void{
    const coordinates: LonAndLat = JSON.parse(location.location);
    this.createMarekrs(coordinates.lng, coordinates.lat, location, qID, pId);
  }

  // Helper function that adds markers on the map
  createMarekrs(Lon, Lat, location: Location, qID, pId): Feature{
    const marker = new Feature({
      geometry: new Point(fromLonLat([Lon, Lat])),
      name: 'marker'
    });
    marker.setStyle(new Style({
      image: new Icon(({
        crossOrigin: 'anonymous',
        src: './assets/mapIcons/place.png',
        scale: 0.08,
      }))
    }));
    marker.setId(location.id);
    marker.set('QuestionId', qID);
    marker.set('PairId', pId);
    marker.set('Radius', location.coverRadius);
    this.vectorSource.addFeature(marker);
  }

  openModal(id: string, element: Question): void {
    this.modalService.open(id);
  }
  closeModal(id: string): void {
    this.modalService.close(id);
  }

  // Builds map and adds all required components to make the map work
  private initializeMap(): void {
    this.vectorSource = new VectorSource({
      features: []
    });
    this.vectorLayer = new VectorLayer({
      source: this.vectorSource
    });
    this.tileLayer = new TileLayer({
      source: new OSM()
    });
    this.view = new View({
      center: fromLonLat([24.6675, 59.39503]),
      zoom: 16
    });

    this.map = new Map({
      controls: defaultControls().extend([new BackControl(this.urlLocation), new FullScreen({
        source: 'fullscreen',
      })]),
      target: 'map',
      layers: [this.tileLayer, this.vectorLayer],
      view: this.view,
    });
    this.map.set('GPSMode', 'on');

    this.geolocation = new Geolocation({
      trackingOptions: {
        enableHighAccuracy: true,
      },
      projection: this.view.getProjection(),
    });
  }
  // Main function that checks if player clicks on markers
  private updateMap(): void {
    this.getPlayerLocation();
    this.map.on('click', (data) => {
      if (true) {
        this.map.forEachFeatureAtPixel(data.pixel, (feature) => {
          const id = feature.get('QuestionId');
          this.currentPairId = feature.get('PairId');
          if (id !== undefined && id !== -1) {
            this.getQuestion(id, feature);
          }
        });
      }
    });
  }

}
