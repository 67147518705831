import { Control} from 'ol/control';

export class GPSControl extends Control {
  button;
    constructor() {
        super({});

        this.button = document.createElement('span');
        this.button.className = 'btn center-btn center-on';

        const element = document.createElement('div');
        element.className = 'fixed-bottom mt-3 pt-5 pl-2';
        element.appendChild(this.button);

        Control.call(this, {
            element: element
        });

        this.button.addEventListener('click', this.setMode.bind(this), false);
    }

    setMode(): void{
        if (super.getMap().get('GPSMode') === 'on'){
          this.button.classList.remove('center-on');
          this.button.classList.add('center-off');
          super.getMap().set('GPSMode', 'off');
        }
        else{
          this.button.classList.add('center-on');
          this.button.classList.remove('center-off');
          super.getMap().set('GPSMode', 'on');
        }
    }
}
