<section class="page_404">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 ">
        <div class="col-sm-10 col-sm-offset-1  text-center mx-auto">
          <div class="four_zero_four_bg">
            <h1 class="text-center ">404</h1>
          </div>
          <div>
            <h2>
              Tundub, et olete eksinud
            </h2>
            <a routerLink="/avaleht" class="btn btn-success">Mine avalehele</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
