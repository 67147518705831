<div class="container-fluid m-0">
  <div class="row m-0">

    <div class="col-10 text-center my-4">
      <h1>Muudad küsimustikku {{setName}}</h1>
    </div>
  </div>

  <div class="container">
    <div class="row m-0 text-center">
      <div class="col-sm-12 col-md-12 col-lg-10 pb-3 table-color" id="questions">
        <h2>Vaata loodud küsimusi</h2>
        <table mat-table [dataSource]="questionSet">
          <!-- ID column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef class="text-center">Nr.</th>
            <td mat-cell *matCellDef="let elem; let i = index">{{i + 1}}</td>
          </ng-container>

          <!-- Question column -->
          <ng-container matColumnDef="question">
            <th mat-header-cell *matHeaderCellDef class="text-center">Küsimus</th>
            <td mat-cell *matCellDef="let elem">{{elem.questionText}}</td>
          </ng-container>

          <!-- Answer column -->
          <ng-container matColumnDef="answer">
            <th mat-header-cell *matHeaderCellDef class="text-center">Vastus(ed)</th>
            <td mat-cell *matCellDef="let elem">{{elem.answer}}</td>
          </ng-container>

          <!-- Type column -->
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef class="text-center">Tüüp</th>
            <td mat-cell *matCellDef="let elem">{{elem.type}}</td>
          </ng-container>

          <!-- Action column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="text-center"> Tegevus </th>
            <td mat-cell *matCellDef="let element" class="action-link">
              <a (click)="editQuestion(element, element.type); openModal(element.type, element, element);"
                 class="btn btn-outline-success w-auto">Muuda</a>
              <a (click)="deleteQuestion(element)" class="btn btn-outline-danger w-auto margin-t2
              mt-lg-0 margin-l2-btn">Kustuta</a>
            </td>
          </ng-container>

          <!-- Draw rows and columns -->
          <tr mat-header-row  *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row class="row-height" *matRowDef="let row; columns: columnsToDisplay"></tr>
        </table>
      </div>
    </div>
  </div>

  <div class="container mt-5">
    <div class="col-sm-12 col-md-12 col-lg-10">
      <div class="row">
        <div class="ml-auto">
          <!-- Trigger Create Multi Answer Modal -->
          <button type="button" class="btn btn-dark btn-adding" (click)="openModalForCreating('multipleChoices');">
            Loo Valikvastustega
          </button>
        </div>

        <div class="mx-auto">
          <!-- Trigger Create Checkbox Modal -->
          <button type="button" class="btn btn-dark btn-adding" (click)="openModalForCreating('checkbox');">
            Loo Mitme vastusevariandiga
          </button>
        </div>

        <div class="mr-auto">
          <!-- Trigger Create Text Modal -->
          <button type="button" class="btn btn-dark btn-adding" (click)="openModalForCreating('text');">
            Loo Vabaltvastatav
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row fixed-bottom text-center mx-auto">
  <div class="col">
    <button class="btn btn-info" routerLink="../..">Tagasi</button>
  </div>
</div>

<!--Valikvastustega editing-->
<jw-modal id="multipleChoicesEdit">
  <div class="col-sm-12 col-md-12 col-lg-12 form text-center">
    <h2>Muuda küsimust</h2>
    <form class="question-form">
      <mat-form-field class="question" appearance="outline">
        <mat-label>Küsimus</mat-label>
        <input id="questionMultipleChoicesEdit" matInput name="question" type="text" required>
      </mat-form-field>

      <br>

      <mat-form-field class="choices text-center mb-3">
        <mat-chip-list #chipListMultipleChoicesEdit aria-label="Choices">
          <mat-chip *ngFor="let choice of choicesMultiple; let id = index" [selectable]="selectable" [removable]="removable"
                    (removed)="remove(choice, 'multipleChoices')" color="green" [ngClass]="setBg(id, 'multipleChoices', 'edit_')" [attr.isSelected]="false" (click)="selectCorrectAnswer(id, choice, 'multipleChoices', 'edit_')" id="edit_multipleChoices_choice_{{id}}">
            {{choice.text}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input [required] placeholder="Valikvastused: vajuta Enter et lisada mitu vastust ja X et kustutada."
                 [matChipInputFor]="chipListMultipleChoicesEdit"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="addOnBlur"
                 (matChipInputTokenEnd)="addAnswer($event, 'multipleChoices')">
        </mat-chip-list>
      </mat-form-field>

      <br>

      <mat-form-field class="correctAnswer text-center mx-md-auto mr-lg-2 mb-lg-3"  appearance="fill">
        <mat-label >Correct answer</mat-label>
        <input id="answerMultipleChoicesEdit" type="text" matInput name="answer" required>
      </mat-form-field>

      <mat-form-field class="points text-center ml-lg-2" appearance="fill">
        <mat-label>Pluss punkte</mat-label>
        <input id="pointsTrueMultipleChoicesEdit" type="number" matInput required>
      </mat-form-field>

      <mat-form-field class="points text-center ml-lg-2" appearance="fill">
        <mat-label>Miinus punkte</mat-label>
        <input id="pointsFalseMultipleChoicesEdit" type="number" matInput required>
      </mat-form-field>

      <br>
      <div class="modal-footer">
        <a (click)="updateQuestion(this.modalService.getElementQuestion(), 'MultipleChoices', null); closeModal('multipleChoicesEdit'); emptyChoices('')" class="btn btn-modal btn-info mx-auto">Uuenda</a>
        <button type="reset" class="btn btn-modal btn-danger" (click)="emptyChoices('')">Tühjenda kõik väljad</button>
        <button type="button" class="btn btn-modal btn-secondary mx-auto" (click)="closeModal('multipleChoicesEdit'); emptyChoices('')">Sulge</button>
      </div>
    </form>
  </div>
</jw-modal>

<!--checkbox editing-->
<jw-modal id="checkboxEdit">
  <div class="col-sm-12 col-md-12 col-lg-12 form text-center">
    <h2>Muuda küsimust</h2>
    <form class="question-form">

      <mat-form-field class="question" appearance="outline">
        <mat-label>Küsimus</mat-label>
        <input id="questionCheckboxEdit" matInput name="question" type="text" required>
      </mat-form-field>

      <br>

      <mat-form-field class="choices text-center mb-3">
        <mat-chip-list #chipListCheckBoxChoicesEdit aria-label="Choices">
          <mat-chip *ngFor="let choice of choicesCheckbox; let id = index" [selectable]="selectable" [removable]="removable"
                    (removed)="remove(choice, 'checkbox')" color="green" [ngClass]="setBg(id, 'checkbox', 'edit_')" [attr.isSelected]="false" (click)="selectCheckBoxCorrectAnswer(id, choice, 'checkbox', 'edit_', '')" id="edit_checkbox_choice_{{id}}">
            {{choice.text}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input [required] placeholder="Mitme vastusevariandiga: vajuta Enter et lisada mitu vastust ja X et kustutada."
                 [matChipInputFor]="chipListCheckBoxChoicesEdit"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="addOnBlur"
                 (matChipInputTokenEnd)="addAnswer($event, 'checkbox')">
        </mat-chip-list>
      </mat-form-field>

      <br>

      <mat-form-field class="correctAnswer text-center mx-md-auto mr-lg-2 mb-lg-3"  appearance="fill">
        <mat-label >Correct answer</mat-label>
        <input id="answerCheckboxEdit" type="text" matInput name="answer" required>
      </mat-form-field>

      <mat-form-field class="points text-center ml-lg-2" appearance="fill">
        <mat-label>Pluss punkte</mat-label>
        <input id="pointsTrueCheckboxEdit" type="number" matInput required>
      </mat-form-field>

      <mat-form-field class="points text-center ml-lg-2" appearance="fill">
        <mat-label>Miinus punkte</mat-label>
        <input id="pointsFalseCheckboxEdit" type="number" matInput required>
      </mat-form-field>

      <br>
      <div class="modal-footer">
        <a (click)="updateQuestion(null, 'Checkbox', this.modalService.getElementQuestionCheckBox()); closeModal('checkboxEdit'); emptyChoices('')" class="btn btn-modal btn-info mx-auto">Uuenda</a>
        <button type="reset" class="btn btn-modal btn-danger" (click)="emptyChoices('')">Tühjenda kõik väljad</button>
        <button type="button" class="btn btn-modal btn-secondary mx-auto" (click)="closeModal('checkboxEdit'); emptyChoices('')">Sulge</button>
      </div>
    </form>
  </div>
</jw-modal>

<!--text editing-->
<jw-modal id="textEdit">
  <div class="col-sm-12 col-md-12 col-lg-12 form text-center">
    <h2>Muuda küsimust</h2>
    <form class="question-form">

      <mat-form-field class="question" appearance="outline">
        <mat-label>Küsimus</mat-label>
        <input id="questionTextEdit" matInput name="question" type="text" required>
      </mat-form-field>

      <br>

      <mat-form-field class="correctAnswer text-center mx-md-auto mr-lg-2 mb-lg-3"  appearance="fill">
        <mat-label >Kirjuta vihje</mat-label>
        <input id="hintTextEdit" type="text" matInput name="hint" required>
      </mat-form-field>


      <br>

      <mat-form-field class="correctAnswer text-center mx-md-auto mr-lg-2 mb-lg-3"  appearance="fill">
        <mat-label >Õige vastus</mat-label>
        <input id="answerTextEdit" type="text" matInput name="answer" required>
      </mat-form-field>

      <mat-form-field class="points text-center ml-lg-2" appearance="fill">
        <mat-label>Pluss punkte</mat-label>
        <input id="pointsTrueTextEdit" type="number" matInput required>
      </mat-form-field>

      <mat-form-field class="points text-center ml-lg-2" appearance="fill">
        <mat-label>Miinus punkte</mat-label>
        <input id="pointsFalseTextEdit" type="number" matInput required>
      </mat-form-field>

      <br>

      <div class="modal-footer">
        <a (click)="updateQuestion(this.modalService.getElementQuestion(), 'Text', null); closeModal('textEdit'); emptyChoices('')" class="btn btn-modal btn-info mx-auto">Uuenda</a>
        <button type="reset" class="btn btn-modal btn-danger" (click)="emptyChoices('')">Tühjenda kõik väljad</button>
        <button type="button" class="btn btn-modal btn-secondary mx-auto" (click)="closeModal('textEdit'); emptyChoices('')">Sulge</button>
      </div>
    </form>
  </div>
</jw-modal>

<!--Valikvastustega-->
<jw-modal id="multipleChoices">
  <div class="col-sm-12 col-md-12 col-lg-12 form text-center">
    <h2>Loo valikvastustega küsimus</h2>
    <form class="question-form">

      <mat-form-field class="question" appearance="outline">
        <mat-label>Küsimus</mat-label>
        <input id="questionMultipleChoices" matInput name="question" type="text" required>
      </mat-form-field>

      <br>

      <mat-form-field class="choices text-center mb-3">
        <mat-chip-list #chipListMultipleChoices aria-label="Choices">
          <mat-chip *ngFor="let choice of choicesMultiple; let id = index" [selectable]="selectable" [removable]="removable"
                    (removed)="remove(choice, 'multipleChoices')" color="green" [ngClass]="setBg(id, 'multipleChoices', '')" [attr.isSelected]="false" (click)="selectCorrectAnswer(id, choice, 'multipleChoices', '')" id="multipleChoices_choice_{{id}}">
            {{choice.text}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input [required] placeholder="Valikvastused: vajuta Enter et lisada mitu vastust ja X et kustutada."
                 [matChipInputFor]="chipListMultipleChoices"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="addOnBlur"
                 (matChipInputTokenEnd)="addAnswer($event, 'multipleChoices')">
        </mat-chip-list>
      </mat-form-field>

      <br>

      <mat-form-field class="correctAnswer text-center mx-md-auto mr-lg-2 mb-lg-3"  appearance="fill">
        <mat-label >Vajuta Õige vastuse peale</mat-label>
        <input id="answerMultipleChoices" type="text" matInput name="answer" required>
      </mat-form-field>

      <mat-form-field class="points text-center ml-lg-2" appearance="fill">
        <mat-label>Pluss punkte</mat-label>
        <input id="pointsTrueMultipleChoices" type="number" matInput required>
      </mat-form-field>

      <mat-form-field class="points text-center ml-lg-2" appearance="fill">
        <mat-label>Miinus punkte</mat-label>
        <input id="pointsFalseMultipleChoices" type="number" matInput required>
      </mat-form-field>

      <br>
      <div class="modal-footer">
        <button type="submit" (click)="addQuestion('MultipleChoices'); closeModal('multipleChoices'); emptyChoices('MultipleChoices')" class="btn btn-modal btn-success mx-auto">Lisa küsimus</button>
        <button type="reset" class="btn btn-modal btn-danger mx-auto" (click)="emptyChoices('MultipleChoices')">Tühjenda kõik väljad</button>
        <button type="button" class="btn btn-modal btn-secondary mx-auto" (click)="closeModal('multipleChoices');">Sulge</button>
      </div>
    </form>
  </div>
</jw-modal>

<!--checkbox-->
<jw-modal id="checkbox">
  <div class="col-sm-12 col-md-12 col-lg-12 form text-center">
    <h2>Loo mitme vastusevariandiga küsimus</h2>
    <form class="question-form">

      <mat-form-field class="question" appearance="outline">
        <mat-label>Küsimus</mat-label>
        <input id="questionCheckbox" matInput name="question" type="text" required>
      </mat-form-field>

      <br>

      <mat-form-field class="choices text-center mb-3">
        <mat-chip-list #chipListCheckbox aria-label="Choices">
          <mat-chip *ngFor="let choice of choicesCheckbox; let id = index" [selectable]="selectable" [removable]="removable"
                    (removed)="remove(choice, 'checkbox')" color="green" [ngClass]="setBg(id, 'checkbox', '')" [attr.isSelected]="false" (click)="selectCheckBoxCorrectAnswer(id, choice, 'checkbox', '', '')" id="checkbox_choice_{{id}}">
            {{choice.text}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input [required] placeholder="Mitme vastusevariandiga: vajuta Enter et lisada mitu vastust ja X et kustutada."
                 [matChipInputFor]="chipListCheckbox"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="addOnBlur"
                 (matChipInputTokenEnd)="addAnswer($event, 'checkbox')">
        </mat-chip-list>
      </mat-form-field>

      <br>

      <mat-form-field class="correctAnswer text-center mx-md-auto mr-lg-2 mb-lg-3"  appearance="fill">
        <mat-label>Vajuta Õige(te) vastus(te) peale</mat-label>
        <input id="answerCheckbox" type="text" matInput name="answer" required>
      </mat-form-field>

      <mat-form-field class="points text-center ml-lg-2" appearance="fill">
        <mat-label>Pluss punkte</mat-label>
        <input id="pointsTrueCheckbox" type="number" matInput required>
      </mat-form-field>

      <mat-form-field class="points text-center ml-lg-2" appearance="fill">
        <mat-label>Miinus punkte</mat-label>
        <input id="pointsFalseCheckbox" type="number" matInput required>
      </mat-form-field>

      <br>
      <div class="modal-footer">
        <button type="submit" (click)="addQuestion('Checkbox'); closeModal('checkbox'); emptyChoices('Checkbox')" class="btn btn-modal btn-success mx-auto">Lisa küsimus</button>
        <button type="reset" class="btn btn-modal btn-danger mx-auto" (click)="emptyChoices('Checkbox')">Tühjenda kõik väljad</button>
        <button type="button" class="btn btn-modal btn-secondary mx-auto" (click)="closeModal('checkbox');">Sulge</button>
      </div>
    </form>
  </div>
</jw-modal>

<!--text-->
<jw-modal id="text">
  <div class="col-sm-12 col-md-12 col-lg-12 form text-center">
    <h2>Loo vabaltvastatav küsimus</h2>
    <form class="question-form">

      <mat-form-field class="question" appearance="outline">
        <mat-label>Küsimus</mat-label>
        <input id="questionText" matInput name="question" type="text" required>
      </mat-form-field>

      <br>

      <mat-form-field class="correctAnswer text-center mx-md-auto mr-lg-2 mb-lg-3"  appearance="fill">
        <mat-label >Kirjuta vihje</mat-label>
        <input id="hintText" type="text" matInput name="hint" required>
      </mat-form-field>

      <mat-form-field class="correctAnswer text-center mx-md-auto mr-lg-2 mb-lg-3"  appearance="fill">
        <mat-label >Kirjuta õige vastus</mat-label>
        <input id="answerText" type="text" matInput name="answer" required>
      </mat-form-field>

      <mat-form-field class="points text-center ml-lg-2" appearance="fill">
        <mat-label>Pluss punkte</mat-label>
        <input id="pointsTrueText" type="number" matInput required>
      </mat-form-field>

      <mat-form-field class="points text-center ml-lg-2" appearance="fill">
        <mat-label>Miinus punkte</mat-label>
        <input id="pointsFalseText" type="number" matInput required>
      </mat-form-field>

      <br>
      <div class="modal-footer">
        <button type="submit" (click)="addQuestion('Text'); closeModal('text'); emptyChoices('Text')" class="btn btn-modal btn-success mx-auto">Lisa küsimus</button>
        <button type="reset" class="btn btn-modal btn-danger mx-auto" (click)="emptyChoices('Text')">Tühjenda kõik väljad</button>
        <button type="button" class="btn btn-modal btn-secondary mx-auto" (click)="closeModal('text');">Sulge</button>
      </div>
    </form>
  </div>
</jw-modal>

<app-tutorial [modalTitle]="'Tere tulemast küsimuste loomise lehele'"
              [modalBody]="'<h3>Kuidas luua küsimistikke/küsimusi koos tüübiga?</h3>' +
               ' <p>Vajutage \'Loo Valikvastusega\' peale, mille järel kuvatakse valikvastusega küsimuse loomine.</p>' +
               ' <p> Vajutage \'Loo Mitme vastusevariandiga\' peale, mille järel kuvatakse mitme vastusevariandiga küsimuse loomine, kus saate anda küsimuse vastusele mitu õiget vastust.</p>' +
               ' <p> Vajutage \'Loo Vabaltvastatav\' peale, mille järel kuvatakse Vabaltvastatav küsimuse loomine, kus saate luua kindla vastusega küsimuse koos vihjega.</p>' +
                '<p> Kui küsimused on valmis, vajutage  \'Tagasi\' nuppu.</p>'"
              [showModal]="getShowModal()"
              [isTutorialActive]="false"
></app-tutorial>
