import {ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivationEnd, Router, RouterEvent} from '@angular/router';
import {LoadingScreenService} from './services/loading-screen.service';
import {Subscription} from 'rxjs';
import {LoaderState} from './interfaces/loader';
import {NavbarService} from './services/navbar.service';
import {ModalService} from './services/modal.service';
import {TutorialService} from './services/tutorial.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  private getComponent: string;
  private getChildComponent: string;
  showLoadingScreen = false;
  private subscription: Subscription;
  innerWidth: any;

  constructor(private router: Router,
              private loadingScreenService: LoadingScreenService,
              private changeDetectorRef: ChangeDetectorRef,
              public nav: NavbarService,
              public modalService: ModalService,
              public tutorialService: TutorialService
  ) {
    router.events.subscribe((val: RouterEvent) => {
      if (val instanceof ActivationEnd) {
        if (typeof val.snapshot.component !== 'string') {
          this.getComponent = val.snapshot.component.name;
          if (val.snapshot.children){
            val.snapshot.children.forEach(child => {
              this.getChildComponent = child.component['name'];
            });
          }
        }
        this.setBgImgAndHideNavBar();
      }
    });
  }
  ngOnInit(): void {
    this.showLoadingScreenOnMap();
    this.innerWidth = window.innerWidth;
    this.nav.show();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  splashScreenGreetingPage(): boolean {
    return this.getComponent === 'GreetingComponent';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.innerWidth = window.innerWidth;
  }

  setBgImgAndHideNavBar(): boolean {
    if (this.getComponent === 'MapComponent') {
      this.hideNavbarMobileView();
    } else {
      this.nav.show();
    }
    if (this.getComponent === 'GreetingComponent') {
      return true;
    } else if (this.getComponent === 'RoomComponent') {
      return true;
    } else if (this.getComponent === 'QuestionsanswersComponent') {
      return true;
    } else { return this.getComponent === 'ContactComponent'; }
  }

  hideNavbarMobileView(): void {
    const myTag = document.getElementById('map');
    try {
        if (this.innerWidth <= 768) {
          this.nav.hide();
          myTag.classList.add('h-100');
        } else {
          this.nav.show();
          myTag.classList.remove('h-100');
        }
    } catch (e) {

    }
  }

  showLoadingScreenOnMap(): void {
    this.subscription = this.loadingScreenService.loaderState
      .subscribe((state: LoaderState) => {
        // disabled loading screen on map component
        if (this.getComponent !== 'MapComponent' && this.getChildComponent !== 'GameMasterComponent') {
          this.showLoadingScreen = state.show;
        } else {
          this.showLoadingScreen = false;
        }
        this.changeDetectorRef.detectChanges();
      });
  }
}
