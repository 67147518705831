import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Question, QuestionCheckBox} from '../interfaces/question';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {
  private questionsUrl = 'api/questions/';
  private questionTypeUrl: string;
  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };
  constructor(private http: HttpClient) { }

  getQuestions(setId: number): Observable<Question[]> {
    return this.http.get<Question[]>('api/questions/set/' + setId);
  }
  getQuestionById(id: number): Observable<any> {
    return this.http.get<any>(this.questionsUrl + id);
  }

  postQuestion(questionObj: Question, questionType: string): Observable<Question> {
    this.setUrl(questionType);
    return this.http.post<Question>(this.questionsUrl + this.questionTypeUrl, questionObj, {});
  }

  postQuestionCheckBox(questionObj: QuestionCheckBox, questionType: string): Observable<Question> {
    this.setUrl(questionType);
    return this.http.post<Question>(this.questionsUrl + this.questionTypeUrl, questionObj, {});
  }

  putQuestion(questionObj: Question, questionType: string): Observable<Question> {
    this.setUrl(questionType);
    return this.http.put<Question>(this.questionsUrl + this.questionTypeUrl, questionObj, {});
  }

  putQuestionCheckBox(questionObj: QuestionCheckBox, questionType: string): Observable<Question> {
    this.setUrl(questionType);
    return this.http.put<Question>(this.questionsUrl + this.questionTypeUrl, questionObj, {});
  }

  removeQuestion(element: Question): Observable<any> {
    return this.http.delete(this.questionsUrl + '/' + element.id);
  }

  setUrl(questionType: string): void {
    if (questionType === 'MultipleChoices') {
      this.questionTypeUrl = 'multiple';
    }
    if (questionType === 'Checkbox') {
      this.questionTypeUrl = 'checkbox';
    }
    if (questionType === 'Text') {
      this.questionTypeUrl = 'text';
    }
  }
}
