import { Pairset } from '../interfaces/pairset';
import { Pair } from '../interfaces/pair';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PairsetsService {
  private pairSetUrl = 'api/pair_sets';
  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  constructor(private http: HttpClient) { }

  getAllPairSets(): Observable<Pairset[]> {
    return this.http.get<Pairset[]>(this.pairSetUrl);
  }

  createPairset(pairsetobj): Observable<Pairset> {
      return this.http.post<Pairset>(this.pairSetUrl, pairsetobj, {});
  }

  getPairsetById(id: number): Observable<Pairset> {
    return this.http.get<Pairset>(this.pairSetUrl + '/' + id);
  }

  deletePairset(id: number): Observable<Pairset> {
    return this.http.delete<Pairset>(this.pairSetUrl + '/' + id);
  }

  copyPairset(id, pairsetobj): Observable<Pairset> {
    return this.http.post<Pairset>(this.pairSetUrl + '/copy/' + id, pairsetobj, {});
  }

  getAllPairsetsByRoomId(id: number): Observable<Pairset[]> {
    return this.http.get<Pairset[]>(this.pairSetUrl + '/room/' + id);
  }
  getAllOriginalPairsetsByRoomId(id: number): Observable<Pairset[]> {
    return this.http.get<Pairset[]>(this.pairSetUrl + '/original/' + id);
  }
  getAllCopyPairsetsByParentId(id: number): Observable<Pairset[]> {
    return this.http.get<Pairset[]>(this.pairSetUrl + '/copy/' + id);
  }

  deleteAllPairsetsByRoomId(id: number): Observable<Pairset> {
    return this.http.get<Pairset>(this.pairSetUrl + '/room/' + id);
  }
}
