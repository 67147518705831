import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TutorialService {
  private viewActiveRoom = false;
  private ifRoomActivated = false;
  private isTutorialActive = false;
  private ifGoingToRedirectToPage = false;
  private ifGoingToCreateNewQuestionSet = false;
  private ifGenPairAuto = false;
  private ifLookedQuestions = false;

  constructor() { }


  setTutorialActive(isTutorialActive): void {
    this.isTutorialActive = isTutorialActive;
  }

  getTutorialActive(): boolean {
    return this.isTutorialActive;
  }

  setIfGoingToRedirectToPage(createNew: boolean): void {
    this.ifGoingToRedirectToPage = createNew;
  }
  getIfGoingToRedirectToPage(): boolean {
    return this.ifGoingToRedirectToPage;
  }

  setIfGoingToCreateNewQuestionSet(createNew: boolean): void {
    this.ifGoingToCreateNewQuestionSet = createNew;
  }

  getIfGoingToCreateNewQuestionSet(): boolean {
    return this.ifGoingToCreateNewQuestionSet;
  }

  setIfGenPairAuto(createNew: boolean): void {
    this.ifGenPairAuto = createNew;
  }

  getIfGenPairAuto(): boolean {
    return this.ifGenPairAuto;
  }

  setIfLookedQuestions(createNew: boolean): void {
    this.ifLookedQuestions = createNew;
  }

  getIfLookedQuestions(): boolean {
    return this.ifLookedQuestions;
  }

  setIfRoomActivated(createNew: boolean): void {
    this.ifRoomActivated = createNew;
  }

  getIfRoomActivated(): boolean {
    return this.ifRoomActivated;
  }

  setViewActiveRoom(createNew: boolean): void {
    this.viewActiveRoom = createNew;
  }

  getViewActiveRoom(): boolean {
    return this.viewActiveRoom;
  }
}
