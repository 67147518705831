import { VisitedPoint } from '../interfaces/visitedPoint';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VisitedPointsService {
  private visitedPointsUrl = 'api/visited_points';
  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
  };
  constructor(private http: HttpClient) { }

  createVisitedPoint(visitedPointObj): Observable<VisitedPoint> {
    return this.http.post<VisitedPoint>(this.visitedPointsUrl, visitedPointObj, {});
  }
  getVisitedPointById(id: number): Observable<VisitedPoint> {
    return this.http.get<VisitedPoint>(this.visitedPointsUrl + '/' + id);
  }
  getVisitedPointsByActiveRoomId(id: number): Observable<VisitedPoint[]> {
    return this.http.get<VisitedPoint[]>(this.visitedPointsUrl + '/active_room/' + id + '/');
  }
  getVisitedPointsByTeamId(id: number): Observable<VisitedPoint[]> {
    return this.http.get<VisitedPoint[]>(this.visitedPointsUrl + '/team/' + id);
  }
}
