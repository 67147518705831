<div class="container" >
  <div>
  </div>
  <div class="row" style="border-color: #343A40">
    <div class="col-md-3 col-sm-6 mt-3">
      <div class="card text-center" [ngClass]="{'selected': !tutorialService.getIfRoomActivated()}" (click)="openModal('activateRoom')">
        <h4 id="helpMe" class="card-title text-right" data-toggle="tooltip" title='Ruumi aktiveerimiseks on vaja vajutada "+" märgile, seejärel tuleb ruumile nimi anda.... '>
          <i class="material-icons">
            help
          </i>
        </h4>
        <div class="text-center iconSvg">
          <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
            <path id="plus1" fill="#342b60" d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path id="plus2" fill="#342b60" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
          </svg>
        </div>
        <h3 class="mt-3 mb-3">Aktiveeri Uus Ruum</h3>
        <p class="card-text">Vajuta siia, et aktiveerida uus ruum</p>
      </div>
    </div>
<!--    Modal for creating room-->
    <jw-modal id="activateRoom">
        <div class="modal-header">
          <h1 class="modal-title" id="createNewRoom">Aktiveeri uus ruum</h1>
          <button type="button" class="close h3" data-dismiss="modal" aria-label="Close" (click)="closeModal('activateModal')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <input type="text" name="name" class="question" id="input" required autocomplete="off" />
          <label for="input"><span>Ruumi nimi</span></label>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary mr-auto" (click)="activateNewRoom(); closeModal('activateRoom'); tutorialService.setViewActiveRoom(true)">Aktiveeri uus ruum</button>
          <button type="button" class="btn btn-secondary" (click)="closeModal('activateRoom')" >Sulge</button>
        </div>
    </jw-modal>
<!--    Modal for deactivating room-->
    <div class="modal fade" id="removeRoom" tabindex="-1" role="dialog" aria-labelledby="createNewRoom" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close h3" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h4>Olete kindel, et tahate deaktiveerida ruumi?</h4>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary mr-auto" (click)="removeRoom(deleteRoom)">Kustuta ruum</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Sulge</button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-3 col-sm-6 mt-3" *ngFor="let room of activeRooms |
    slice: (curPage * pageSize) - pageSize :curPage * pageSize; index as i" >
      <div class="card text-center" [ngClass]="{'selected': tutorialService.getViewActiveRoom()}">
        <h4 class="card-title text-right" (click)="confirmDelete(room)"><i class="material-icons" id="deleteRoom" data-toggle="modal" data-target="#removeRoom">close</i></h4>
        <div class="text-center iconSvg" [routerLink]="['../aktiveeri/', room.id]" (click)="tutorialService.setTutorialActive(false)">
          <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" class="bi bi-pencil" viewBox="0 0 16 16">
            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
          </svg>
        </div>
        <h3 class="mt-3 mb-3">{{room.roomName}}</h3>
        <h3 class="mt-3 mb-3">Liitumis kood: {{room.joinCode}}</h3>
        <p class="card-text">Vajuta siia, et muuta aktiveeritud ruumi</p>
      </div>
    </div>

  </div>
  <div class="d-flex mt-3">
    <ul class="pagination mx-auto">
      <li class="page-item">
        <button class="page-link" aria-label="Previous" [disabled]="curPage == 1" (click)="curPage = curPage - 1">
          <h4 aria-hidden="true">&laquo;</h4>
          <h4 class="sr-only">Eelmine</h4>
        </button>
      </li>
      <li class="page-item"><a class="page-link">
        <h4>Leht {{curPage}} / {{ numberOfPages() }}</h4>
      </a></li>
      <li class="page-item">
        <button class="page-link" aria-label="Next" [disabled]="curPage >= activeRooms.length/pageSize" (click)="curPage = curPage + 1">
          <h4 aria-hidden="true">&raquo;</h4>
          <h4 class="sr-only">Järgmine</h4>
        </button>
      </li>
    </ul>
  </div>
</div>

<div class="row fixed-bottom text-center mx-auto">
  <div class="col">
    <button class="btn btn-info" routerLink="..">Tagasi</button>
  </div>
</div>

<app-tutorial *ngIf="!tutorialService.getIfRoomActivated()"
              [modalTitle]="'Tere tulemast ruumi aktiveerimise lehele'"
              [modalBody]="'<p>Vajutage \'Aktiveeri ruum\' ning andke aktiivsele ruumile nimi.</p>' +
               '<p>Seejärel vajutage \'Aktiveeri uus ruum\' ning Teile tehtakse uus liitumiskoodiga aktiivne ruum.</p>'"
              [showModal]="modalService.getShowModal()">
</app-tutorial>

<app-tutorial *ngIf="tutorialService.getIfRoomActivated()"
              [modalTitle]="'Ruum on aktiveeritud'"
              [modalBody]="'<p>Vajutage oma loodud ruumi peale ja kuvatakse teile ruumi ühinemise kood.</p>'"
              [showModal]="modalService.getShowModal()">
</app-tutorial>
