<div id="map" class="map"></div>

<jw-modal id="addQuestion">
  <div class="modal-body mb-3">
    <form (ngSubmit)="createLocation()">
      <div class="form-group">
          <label for="answer" class='h2'>Punkti aktiveerimisraadius</label>
          <p class='h5'>Sisestage arv (meetrites) punkti aktiveerimise raadius.</p>
          <p class='h6 text-underline'>Soovitatav miinimumkaugus on 25 meetrit.</p>
          <input type='number' min="25" value='100' [(ngModel)]="coverRadius" id="coverRadius" placeholder="Kirjutage vastus" class="form-control" required [ngModelOptions]="{standalone: true}">
      </div>
      <button type="button" (click)="closeModal('addQuestion')" class="btn btn-danger float-left">Sulge</button>
      <button type="submit" class="btn btn-primary float-right">Loo Punkt</button>
    </form>
</div>
</jw-modal>

<jw-modal id="tooClose">
  <div class='text-center'>
    <p class='h3'>Teie punkt on liiga lähedal teistele. Pange kaugemale</p>
    <button type="button" (click)="closeModal('tooClose')" class="btn btn-danger lg">Sulge</button>
  </div>
</jw-modal>
