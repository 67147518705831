export const QuestionsAnswers = {
  text: [
    {
      title: 'Kuidas ma saan liituda mänguga?',
      subText: '- Vajutades nuppu "mängima", saad liituda ruumiga, kui sul on kood olemas. Kui ei ole, siis saad luua enda ruumi.'
    },
    {
      title: 'Kuidas ma saan sisse logida?',
      subText: '- Logida saab sisse ainult juhul kui sul on UNI-ID. Vajuta nupule "Logi Sisse" ja saad selle kaudu UNI-IDga mängida.'
    },
    {
      title: 'Kas ma pean mängimise eest tasuma?',
      subText: '- Ei, kõik on tasuta.'
    }
  ]
};
