import { Subscription } from 'rxjs';
import { Room } from '../../interfaces/room';
import { RoomService } from './../../services/room.service';
import { Pairset } from '../../interfaces/pairset';
import { PairsetsService } from './../../services/pairsets.service';
import { TeamService } from './../../services/team.service';
import { Team } from '../../interfaces/team';
import { ActivatedRoute, Router } from '@angular/router';
import { ActiveRoom } from '../../interfaces/activeroom';
import { ActiveRoomsService } from '../../services/active-rooms.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-go-play-rooms',
  templateUrl: './go-play-rooms.component.html',
  styleUrls: ['./go-play-rooms.component.css', '../../app.component.css']
})
export class GoPlayRoomsComponent implements OnInit, OnDestroy {
  activeRoom: ActiveRoom;
  room: Room;
  joinCode = '';
  teamName = 'T';
  teamMembers = '';
  pairSetId = 0;
  pairSetIds: Pairset[];
  currentsize = 0;
  teams: Team[] = [];
  private subscriptions: Subscription[] = [];

  orderForm: FormGroup;
  formTeamMembersNames: FormArray;

  constructor(
    private router: Router,
    private pairsetS: PairsetsService,
    private roomS: RoomService,
    private teamService: TeamService,
    private activatedRoute: ActivatedRoute,
    public acService: ActiveRoomsService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.formTeamMembersNames = this.formBuilder.array([]);
    this.orderForm = this.formBuilder.group({
      items: this.formBuilder.array([this.createItem()])
    });
    const sub = this.activatedRoute.params.subscribe(
      (params) => {
        this.joinCode = params.joincode;
        const subscription = this.acService.getActiveGameByJoinCode(this.joinCode).subscribe(room => {
          if (room) {
            this.activeRoom = room;
            this.getRoom();
          }
          this.getPairIds(room.roomId);
          this.getActiveTeams();
        });
        this.subscriptions.push(subscription);
      });
    this.subscriptions.push(sub);
  }
  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  getRoom(): void {
    const sub = this.roomS.getRoomById(this.activeRoom.roomId).subscribe(room => this.room = room);
    this.subscriptions.push(sub);
  }
  getPreAssignedSet(): void {
    const sub = this.pairsetS.getAllOriginalPairsetsByRoomId(this.activeRoom.roomId).subscribe(sets => {
      const pairSetobj = {
        type: 'copy'
      };
      const subTeams = this.teamService.getAllTeamsByActiveRoomId(this.activeRoom.id).subscribe(teams => {
        this.currentsize = 0;
        if (teams) {
          this.currentsize = teams.length;
        }
        if (this.currentsize < this.room.teamLimit) {
          const sortedsets = sets.sort((first, second) => 0 - (first.id > second.id ? -1 : 1));
          this.pairSetId = sortedsets[this.currentsize].id;
          const subPairSets = this.pairsetS.copyPairset(this.pairSetId, pairSetobj).subscribe(() => {
            this.registerTeam();
          });
          this.subscriptions.push(subPairSets);
        } else {
          alert('Tiimid on täis');
        }
      });
      this.subscriptions.push(subTeams);
    });
    this.subscriptions.push(sub);
  }

  copyRoomPairset(): void {
    const subPairSets = this.pairsetS.getAllOriginalPairsetsByRoomId(this.activeRoom.roomId).subscribe(response => {
      if (response) {
        response.forEach(pairset => {
          const pairSetobj = {
            type: 'copy'
          };
          const subCopyPairSet = this.pairsetS.copyPairset(pairset.id, pairSetobj).subscribe(set => {
            this.pairSetId = set.id;
            this.registerTeam();
          });
          this.subscriptions.push(subCopyPairSet);
        });
      }
    });
    this.subscriptions.push(subPairSets);
  }

  getPairIds(id: number): void {
    const sub = this.pairsetS.getAllPairsetsByRoomId(id).subscribe(sets => {
      this.pairSetIds = sets;
    });
    this.subscriptions.push(sub);
  }

  checkForPairId(): void {
    const sub = this.acService.getAllPairSetsByJoinCodee(this.activeRoom.joinCode).subscribe(sets => {
      if (this.room.gameMode === 'PREASSIGNED') {
        this.getPreAssignedSet();
      } else if (sets) {
        this.pairSetId = sets[0].id;
        this.registerTeam();
      } else {
        this.copyRoomPairset();
      }
    });
    this.subscriptions.push(sub);
  }
  registerTeam(): void {
    const teamObj = {
      activeId: this.activeRoom.id,
      teamName: this.teamName,
      location: '0 0',
      teamMembers: this.teamMembers,
      pairSetId: this.pairSetId
    };
    const sub = this.teamService.createTeam(teamObj).subscribe(() => {
      this.router.navigate(['mang/' + this.joinCode + '/' + this.teamName]);
    });
    this.subscriptions.push(sub);
  }

  checkIfInTeam(): boolean {
    if (this.teams) {
      this.teams.forEach((team) => {
        if (team.teamName === this.teamName) {
          return true;
        }
      });
    }
    return false;
  }
  goPlay(): void {
    this.teamName = (document.getElementById('teamName') as HTMLInputElement).value;
    this.constructTeamPlayers();
    if (this.teamMembers && this.teamName) {
      if (!this.checkIfInTeam()) {
        this.checkForPairId();
      }
    }
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      name: ''
    });
  }

  onChange(): void {
    this.formTeamMembersNames = this.orderForm.get('items') as FormArray;
  }

  addTeamMember(): void {
    this.formTeamMembersNames = this.orderForm.get('items') as FormArray;
    this.formTeamMembersNames.push(this.createItem());
  }

  removeTeamMember(id): void {
    this.formTeamMembersNames = this.orderForm.get('items') as FormArray;
    if (this.formTeamMembersNames.length > 1) {
      this.formTeamMembersNames.removeAt(id);
    }
  }

  getActiveTeams(): void {
    const sub = this.teamService.getAllTeamsByActiveRoomId(this.activeRoom.id).subscribe(response => {
      if (response) {
        this.teams = response;
      }
    });
    this.subscriptions.push(sub);
  }

  constructTeamPlayers(): void {
    const teamNameList = [];
    this.formTeamMembersNames.controls.forEach((element, index) => {
      const name = element.value.name;
      if (name.length !== 0) {
        teamNameList.push(name);
      }
    });
    this.teamMembers = teamNameList.join(', ');
  }
}
