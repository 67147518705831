import { PairsetsService } from './../../services/pairsets.service';
import { ActiveRoom } from '../../interfaces/activeroom';
import { ActiveRoomsService } from './../../services/active-rooms.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {ModalService} from '../../services/modal.service';
import {TutorialService} from '../../services/tutorial.service';

@Component({
  selector: 'app-activate-room',
  templateUrl: './activate-room.component.html',
  styleUrls: ['./activate-room.component.css', '../../app.component.css']
})

export class ActivateRoomComponent implements OnInit {
  currentId;
  joinCode;
  activeRooms: ActiveRoom[] = [];
  deleteRoom: ActiveRoom;
  roomName = '';
  curPage: number;
  pageSize: number;


  constructor(private activatedRoute: ActivatedRoute,
              private activeRoomSerivce: ActiveRoomsService,
              public modalService: ModalService,
              public tutorialService: TutorialService
    ) {
    this.activatedRoute.parent.params.subscribe(
      (params) => {
        this.currentId = +params.id;
      });
  }

  ngOnInit(): void {
    this.getActiveRooms();
    this.curPage = 1;
    this.pageSize = 7;
  }

  getActiveRooms(): void{
    this.activeRoomSerivce.getActiveGamesByRoomId(this.currentId).subscribe(activeRoom => {
      if (activeRoom){
        this.activeRooms = activeRoom;
      }
    });
  }
  numberOfPages(): number {
    if (this.activeRooms === null) { return 0; }
    return Math.ceil(this.activeRooms.length / this.pageSize);
  }
  removeRoom(set: ActiveRoom): void{
    this.activeRoomSerivce.deleteActiveGameByRoomCode(set.joinCode).subscribe(() => {
      this.getActiveRooms();
      window.location.reload();
    },
      error => {
        const errorMessage = error.message;
        console.error('Happened this during deleting: ', errorMessage);
    });
  }

  activateNewRoom(): void {
    const roomName = (document.getElementById('input') as HTMLInputElement).value;
    const activeGameObj = {
      roomId: this.currentId,
      roomName: roomName
    };
    this.activeRoomSerivce.activeGame(activeGameObj).subscribe(response => {
      this.getActiveRooms();
      this.tutorialService.setIfRoomActivated(true);
      // window.location.reload();
    });
  }
  confirmDelete(set: ActiveRoom): void {
    this.deleteRoom = set;
  }

  openModal(id: string): void {
    this.modalService.open(id);
  }
  closeModal(id: string): void {
    this.modalService.close(id);
  }
}
