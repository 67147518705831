<div class="container">
  <h1 class="mt-5">KKK</h1>
</div>

<div class="container contact-info">
  <div class="h-75 mt-5 row">
    <div class="col-md-12 col-lg-8">
      <div class="row ml-5 pt-3">
        <div class="col-xl-12" *ngFor="let message of messages.text">
          <h3>
            {{message.title}}
          </h3>
          <p>
            {{message.subText}}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
