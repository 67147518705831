<div id="fullscreen" class="fullscreen">
  <div id="map" class="map"></div>
  <div class="sidepanel">
    <span class="sidepanel-title mt-2 mb-2">Skoorid</span>
    <mat-divider></mat-divider>
    <mat-selection-list #shoes [multiple]="false">
      <mat-list-option *ngFor="let team of teams" [value]="team.id" (click)='centerView(team)'>
        {{team.teamName}} : {{team.score}}
        <mat-divider></mat-divider>
      </mat-list-option>
    </mat-selection-list>
  </div>
</div>

<jw-modal id="checkBoxQuestion">
  <div class="modal-body mb-3">
      <form [formGroup]="form" (ngSubmit)="closeModal('checkBoxQuestion')">
        <p class='h2'>{{questionText}}</p>
        <div class="form-check" *ngFor="let choice of choices">
          <input class="form-check-input" name="{{ choice }}" type="checkbox" id="{{choice}}">
          <label class="form-check-label" for="{{choice}}">
              <p class='h4 mb-2'>  {{choice}}</p>
          </label>
        </div>
        <button type="submit" class="btn btn-info float-right">Sulge</button>
      </form>
  </div>
</jw-modal>

<jw-modal id="textQuestion">
  <div class="modal-body mb-3">
      <form [formGroup]="form" (ngSubmit)="closeModal('textQuestion')">
        <div class="form-group">
            <label for="answer" class='h2'>{{questionText}}</label>
            <p class='h4'>Hint:  {{hint}}</p>
        </div>
        <button type="submit" class="btn btn-info float-right">Sulge</button>
      </form>
  </div>
</jw-modal>

<jw-modal id="multipleChoiceQuestion">
  <div class="modal-body mb-3">
      <form [formGroup]="form" (ngSubmit)="closeModal('multipleChoiceQuestion')">
        <div class="form-group">
            <label for="answers" class='h2'>{{questionText}}</label>
            <select formControlName="answers" id="answers" class="form-control">
              <option *ngFor="let choice of choices; let i = index" [ngValue]="choice" class='h3'>
                <p>{{choices[i]}}</p>
              </option>
            </select>
        </div>
        <button type="submit" class="btn btn-info float-right">Sulge</button>
      </form>
  </div>
</jw-modal>
