import { Injectable } from '@angular/core';
import {Account} from '../interfaces/account';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OAuthService {
  private isAuthenticated = false;
  private account: Account;
  private accountUrl = 'api/accounts';
  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  constructor(private http: HttpClient) { }

  setIsAuthenticated(auth: boolean): void {
    this.isAuthenticated = auth;
  }

  getIsAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  getAllAccounts(): Observable<Account[]> {
    return this.http.get<Account[]>(this.accountUrl);
  }

  getAccountById(id: number): Observable<Account> {
    return this.http.get<Account>(this.accountUrl + '/' + id);
  }

  getAccountByEmail(email: string): Observable<Account> {
    return this.http.get<Account>(this.accountUrl + '/email/' + email);
  }

  getAccountByName(name: string): Observable<Account> {
    return this.http.get<Account>(this.accountUrl + '/name/' + name);
  }

  createAccount(account: { userName: string; email: string }): Observable<Account> {
    return this.http.post<Account>(this.accountUrl, account, {});
  }

  setCurrentAccount(user: Account): void {
    this.account = user;
  }
  getCurrentAccount(): Account {
    return this.account;
  }
}
