import {Inject, Injectable} from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) { }

  setStoreOnLocalStorage(isActive: boolean): void {
    this.storage.set('isTutorialActive', isActive);
  }

  getIsActiveTutorial(): boolean {
    return this.storage.get('isTutorialActive');
  }
}
