import { Team } from '../interfaces/team';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  private teamUrl = 'api/teams';
  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  constructor(private http: HttpClient) { }

  createTeam(teamObj): Observable<Team> {
    return this.http.post<Team>(this.teamUrl, teamObj, {});
  }
  setDetailsTeam(teamObj): Observable<Team> {
    return this.http.put<Team>(this.teamUrl, teamObj, {});
  }

  getTeamById(teamId: number): Observable<Team> {
    return this.http.get<Team>(this.teamUrl + '/' + teamId);
  }
  getAllTeamsByActiveRoomId(activeRoomId: number): Observable<Team[]> {
    return this.http.get<Team[]>(this.teamUrl + '/all/' + activeRoomId);
  }

  getTeamByName(teamName: string): Observable<Team> {
    return this.http.get<Team>(this.teamUrl + '/name/' + teamName);
  }
  updateLocation(teamObj): Observable<Team> {
    return this.http.put<Team>(this.teamUrl + '/location', teamObj, {});
  }
  setStartAndGameEndTime(id: number): Observable<Team> {
    return this.http.put<Team>(this.teamUrl + '/start/' + id, {}, {});
  }
  setTeamFinishTime(id: number): Observable<Team> {
    return this.http.put<Team>(this.teamUrl + '/finish/' + id, {}, {});
  }
}
