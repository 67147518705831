import { Pairset } from '../interfaces/pairset';
import { ActiveRoom } from '../interfaces/activeroom';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActiveRoomsService {
  private activeRoomsUrl = 'api/active';
  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
    observe: 'response'
  };
  constructor(private http: HttpClient) { }

  getAllActiveGames(): Observable<ActiveRoom[]> {
    return this.http.get<ActiveRoom[]>(this.activeRoomsUrl);
  }
  deleteAllActiveGames(): Observable<any> {
    return this.http.delete(this.activeRoomsUrl);
  }
  getActiveGameById(roomId: number): Observable<ActiveRoom> {
    return this.http.get<ActiveRoom>(this.activeRoomsUrl + '/id/' + roomId);
  }
  activeGame(activegameobj): Observable<ActiveRoom> {
    return this.http.post<ActiveRoom>(this.activeRoomsUrl, activegameobj, {});
  }
  deleteActiveGameByRoomCode(roomId: string): Observable<any> {
    return this.http.delete(this.activeRoomsUrl + '/' + roomId);
  }
  getActiveGameByJoinCode(joinCode: string): Observable<ActiveRoom> {
    return this.http.get<ActiveRoom>(this.activeRoomsUrl + '/' + joinCode);
  }
  getActiveGamesByRoomId(roomId: number): Observable<ActiveRoom[]> {
    return this.http.get<ActiveRoom[]>(this.activeRoomsUrl + '/room/' + roomId);
  }
  getAllPairSetsByJoinCodee(joinCode: string): Observable<Pairset[]> {
    return this.http.get<Pairset[]>(this.activeRoomsUrl + '/pairSets/' + joinCode);
  }

}
