<div id="fullscreen" class="fullscreen">
  <div id="map" class="map"></div>
  <div class="sidepanel">
    <span class="sidepanel-title mt-2 mb-2">Paaristikud</span>
    <div class="btn-group" role="group" aria-label="Basic example">
      <button type="button" class="btn btn-secondary" (click)='createPairSet()'>Loo uus paaristik</button>
      <button type="button" class="btn btn-secondary" (click)='copyPairSet()'>Kopeeri paaristik</button>
    </div>
    <mat-divider></mat-divider>
    <mat-selection-list #shoes [multiple]="false">
      <mat-list-option *ngFor="let set of pairSets" [value]="set.id" (click)='drawNewPairs(set.id)'>
        Paaristiku ID: {{set.id}}
        <mat-divider></mat-divider>
      </mat-list-option>
    </mat-selection-list>
  </div>
</div>

<jw-modal id="createNewPair">
  <button type="button" class="close" (click)="resetForm()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class='container'>
  <div class="form-group space-up">
    <label for="questionsets" class="font-weight-bold col-sm-2 col-form-label">Küsimustik</label>
    <select id="questionsets" [(ngModel)]="selectedQuestionSet" (change)="updateQuestions()" class="form-control">
      <option *ngFor="let c of questionSetdata" [ngValue]="c.id">{{c.name}}</option>
    </select>
    <div class="form-group">
    <label for="questions" class="font-weight-bold col-sm-2 col-form-label">Küsimus</label>
    <select id="questions" [(ngModel)]="selectedQuestion" class="form-control">
      <option *ngFor="let c of questions" [ngValue]="c.id">{{c.questionText}}</option>
    </select>
  </div>
    <button (click)='postPair()' class="btn btn-primary float-right">Loo uus paar</button>
    <div class='pb-3'></div>
  </div>
</div>
</jw-modal>

<jw-modal id="pairData">
  <button type="button" class="close" (click)="resetPairForm()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class='container-fluid d-flex flex-column text-center'>
    <p class='h3 text-info'>Paari küsimus:</p>
    <p class='h3'>{{questionText}}</p>
    <button (click)='deletePair()' class="btn btn-danger float-right">Kustuta paar</button>
  </div>
</jw-modal>
