import {Component, OnDestroy, OnInit} from '@angular/core';
import {ArchiveService} from '../../services/archive.service';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {Archive, ArchiveTeams} from '../../interfaces/archive';
import {ModalService} from '../../services/modal.service';

@Component({
  selector: 'app-archive-game-view',
  templateUrl: './archive-game-view.component.html',
  styleUrls: ['./archive-game-view.component.css', '../users-rooms/users-rooms.component.css', '../../app.component.css']
})
export class ArchiveGameViewComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  setId: number;
  setGameName: string;
  data: Archive;
  archivedTeams: ArchiveTeams[];

  constructor(
    private archiveService: ArchiveService,
    private route: ActivatedRoute,
    public modalService: ModalService
  ) { }

  ngOnInit(): void {
    const sub = this.route.paramMap.subscribe(params => {
      this.setId = +params.get('id');
      this.setGameName = params.get('gamename');
    });
    this.subscriptions.push(sub);
    setTimeout(() => {
      this.getArchives();
    }, 200);
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getArchives(): void {
    const sub = this.archiveService.getAllArchivedTeamsByArchiveId(this.setId).subscribe(response => {
      this.archivedTeams = response.sort((q1, q2) => q2.points - q1.points);
    });
    this.subscriptions.push(sub);
  }

  openModal(id: string): void {
    this.modalService.open(id);
  }

  closeModal(id: string): void {
    this.modalService.close(id);
  }

  sortArchiveTeams(sort: string): void {
    if (sort === 'quickestTime') {
      this.archivedTeams.sort((q1, q2) => {
        return ((new Date(q1.endTime) as any) - (new Date(q1.startTime) as any)) -
          ((new Date(q2.endTime) as any) - (new Date(q2.startTime) as any));
      });
    }
    if (sort === 'slowestTime') {
      this.archivedTeams.sort((q1, q2) => {
        return ((new Date(q2.endTime) as any) - (new Date(q2.startTime) as any)) -
          ((new Date(q1.endTime) as any) - (new Date(q1.startTime) as any));
      });
    }
    if (sort === 'teamNameAsc') {
      this.archivedTeams.sort((q1, q2) => (q1.teamName) > (q2.teamName) ? 1 : -1);
    }
    if (sort === 'teamNameDesc') {
      this.archivedTeams.sort((q1, q2) => (q2.teamName) > (q1.teamName) ? 1 : -1);
    }
    if (sort === 'scoreHighest') {
      this.archivedTeams.sort((q1, q2) => (q2.points) - (q1.points));
    }
    if (sort === 'scoreLowest') {
      this.archivedTeams.sort((q1, q2) => (q1.points) - (q2.points));
    }
  }

  calculateTotalTime(startTime: string, endTime: string): number {

    const date1 = new Date(startTime).getTime();
    const date2 = new Date(endTime).getTime();
    // currently not giving right elapsed time
    return date2 - date1;
  }
}
