import { PairsetsService } from './../../services/pairsets.service';
import { Room } from '../../interfaces/room';
import { RoomService } from '../../services/room.service';
import { PairService } from '../../services/pair.service';
import { Component, OnInit } from '@angular/core';
import {Pair} from '../../interfaces/pair';
import {ModalService} from '../../services/modal.service';
import {TutorialService} from '../../services/tutorial.service';
import {Router} from '@angular/router';
import {OAuthService} from '../../services/o-auth.service';
import {LocalStorageService} from "../../services/local-storage.service";

@Component({
  selector: 'app-users-rooms',
  templateUrl: './users-rooms.component.html',
  styleUrls: ['./users-rooms.component.css', '../../app.component.css']
})
export class UsersRoomsComponent implements OnInit {
  data: Array<any>;
  rooms: Room[] = [];
  gameName = '';
  teamLimit: number;
  timeLimit = 60;
  open = false;
  deleteRoom: Room;
  curPage: number;
  pageSize: number;
  redirectTo: Room;
  gameModes = ['Normal', 'Rush', 'Preassigned'];
  selectedGameType: string;


  constructor(private pairService: PairService,
              private pairSetService: PairsetsService,
              private roomservice: RoomService,
              public modalService: ModalService,
              public tutorialService: TutorialService,
              private router: Router,
              private authService: OAuthService
  ) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.getRooms(true);
    }, 400);
    this.curPage = 1;
    this.pageSize = 7;
  }

  openInput(): void{
    if (this.selectedGameType === 'Preassigned'){
      this.open = true;
    }else{
      this.open = false;
      this.teamLimit = undefined;
    }
  }
  createPairSets(id: number): void{
    const pairSetObj = {
      roomId: id,
      type: 'original'
    };
    this.pairSetService.createPairset(pairSetObj).subscribe(() => {
      this.getRooms(false);
    });
  }

  getRooms(firstLoad: boolean): void{
    this.roomservice.getRoomsByCreatorId(this.authService.getCurrentAccount().id).subscribe(room => {
      this.rooms = room;
      if (!firstLoad) {
        this.redirectTo = this.rooms.slice(-1)[0];
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate(['roomediting', this.redirectTo.id])
            .then(() => {
              window.location.reload();
            });
        });
      }
    });
  }

  getPairById(id: number): Pair {
    let pair: Pair;
    this.pairService.getPairbyId(id).subscribe(pairData => pair = pairData);
    return pair;
  }

  removeRoom(set: Room): void{
    this.roomservice.removeRoom(set.id).subscribe(() => {
        this.getRooms(true);
        window.location.reload();
      },
      error => {
        const errorMessage = error.message;
        console.error('Happened this during deleting: ', errorMessage);
      });
  }

  addRoom(): void {
    const roomName = (document.getElementById('input') as HTMLInputElement).value;
    if (!this.timeLimit){
      this.timeLimit = 60;
    }
    if ((roomName && this.selectedGameType !== 'Preassigned' && !this.teamLimit) || (roomName &&
      this.selectedGameType === 'Preassigned' && this.teamLimit !== null)){
      const newRoom = {
        creatorId: this.authService.getCurrentAccount().id,
        gameName: roomName,
        gameMode: this.selectedGameType,
        teamLimit: this.teamLimit,
        gameLength: this.timeLimit,
        firstView: ''
      };
      if (this.selectedGameType === 'Preassigned') {
        this.setSelectedRoomGameType('PREASSIGNED');
      }
      this.roomservice.postRoom(newRoom)
        .subscribe(room => {
            this.createPairSets(room.id);
          },
          error => {
            const errorMessage = error.message;
            console.error('Happened this during posting: ', errorMessage);
          });
    }else{
      alert('Täida lüngad');
    }
  }
  numberOfPages(): number {
    if (this.rooms != null) {
      return Math.ceil(this.rooms.length / this.pageSize);
    }
    return 0;
  }
  confirmDelete(set: Room): void {
    this.deleteRoom = set;
  }

  setShowModal(showModal: boolean): void {
    this.modalService.setShowModal(showModal);
  }

  getShowModal(): boolean {
    return this.modalService.getShowModal();
  }

  setSelectedRoomGameType(gameType: string): void {
    if (gameType === 'PREASSIGNED') {
      this.setShowModal(true);
    }
  }
}
