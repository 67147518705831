import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalService} from '../../services/modal.service';
import {TutorialService} from '../../services/tutorial.service';
import {RoomEditing} from './messages';

@Component({
  selector: 'app-room-editing',
  templateUrl: './room-editing.component.html',
  styleUrls: ['./room-editing.component.css', '../../app.component.css']
})
export class RoomEditingComponent implements OnInit {
  messages;
  mini = true;
  currentRoomId: number;
  constructor(public router: Router,
              private activatedRoute: ActivatedRoute,
              public modalService: ModalService,
              public tutorialService: TutorialService
  ) {
    this.messages = RoomEditing;
  }

  ngOnInit(): void {
    this.currentRoomId = this.activatedRoute.snapshot.params.id;
  }

  toggleSidebar(): void {
    if (this.mini) {
      document.getElementById('mySidebar').style.width = '250px';
      document.getElementById('main').style.marginLeft = '250px';
      this.mini = false;
    } else {
      document.getElementById('mySidebar').style.width = '85px';
      document.getElementById('main').style.marginLeft = '85px';
      this.mini = true;
    }
  }

  getShowModal(): boolean {
    return this.modalService.getShowModal();
  }

}
