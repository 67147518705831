<div class="d-flex">
  <select class="form-select h-25" (change)="sortArchiveTeams($event.target.value)">
    <option selected>Sorteeri</option>
    <option value="scoreHighest">Punktide järgi Kõrgeim</option>
    <option value="scoreLowest">Punktide järgi Madalaim</option>
    <option value="quickestTime">Aja järgi Kiireim</option>
    <option value="slowestTime">Aja järgi Aeglaseim</option>
    <option value="teamNameAsc">Tiiminime järgi A-Z</option>
    <option value="teamNameDesc">Tiiminime järgi Z-A</option>
  </select>
</div>


<div class="row w-100 h-100">
  <div class="col-10" *ngIf="archivedTeams !== undefined && archivedTeams !== null">
    <h2>
      {{this.setGameName}}
    </h2>
    <div class="row">
      <div class="col-md-3 col-sm-6 mt-3" *ngFor="let archive of archivedTeams; index as i" >
        <div class="card text-center" (click)="openModal('viewTeam_' + archive.teamName)">
          <h3 class="mt-3 mb-3">{{archive.teamName}}</h3>
        </div>


        <jw-modal id="{{ 'viewTeam_' + archive.teamName }}">
          <div class="col-sm-12 col-md-12 col-lg-12 form text-center">
            <h2>Tiim <i class="font-weight-bold"> {{archive.teamName}} </i></h2>
            <div class="modal-content">
              <h3>Tiimiliikmed: <i class="font-weight-bold"> {{archive.teamMembers}} </i></h3>
              <h3>Vastatud küsimusi kokku <i> {{ archive.totalAnswers}} </i></h3>
              <h3>Õigesti vastatud <i>{{ archive.correctAnswers}}</i></h3>
              <h3>Valesti vastatud <i>{{ archive.wrongAnswers}}</i></h3>
              <h3>Punkte kokku <i>{{ archive.points}}</i></h3>
              <h3>Algus aeg <i>{{ archive.startTime | date:'HH:mm:ss'}}</i></h3>
              <h3>Lõpp aeg <i>{{ archive.endTime | date:'HH:mm:ss'}}</i></h3>
              <h3>Aeg kokku <i>{{ calculateTotalTime(archive.endTime, archive.startTime) | date: 'HH:mm:ss'}}</i></h3>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-modal btn-secondary mx-auto" (click)="closeModal('viewTeam_' + archive.teamName);">Sulge</button>
            </div>
          </div>
        </jw-modal>

      </div>
    </div>
  </div>
</div>

<div class="row fixed-bottom text-center mx-auto">
  <div class="col">
    <button class="btn btn-info" routerLink="../..">Tagasi</button>
  </div>
</div>
