import { Injectable } from '@angular/core';
import {Question, QuestionCheckBox} from '../interfaces/question';
import {Questionset} from '../interfaces/questionset';
import {Locationset} from '../interfaces/locationset';
import {LocalStorageService} from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modals: any[] = [];
  private showModal = false;
  private elementQuestion: Question;
  private elementQuestionSet: Questionset;
  private elementLocationSet: Locationset;
  private elementQuestionCheckBox: QuestionCheckBox;

  constructor(
    private localStorageService: LocalStorageService
  ) { }

  add(modal: any): void {
    // add modal to array of active modals
    this.modals.push(modal);
  }

  remove(id: string): void {
    // remove modal from array of active modals
    this.modals = this.modals.filter(x => x.id !== id);
  }

  open(id: string): void {
    // open modal specified by id
    const modal = this.modals.find(x => x.id === id);
    modal.open();
  }

  close(id: string): void {
    // close modal specified by id
    const modal = this.modals.find(x => x.id === id);
    modal.close();
  }

  setElementQuestion(element: Question): void {
    this.elementQuestion = element;
  }

  getElementQuestion(): Question {
    return this.elementQuestion;
  }

  setElementQuestionSet(element: Questionset): void {
    this.elementQuestionSet = element;
  }

  getElementQuestionSet(): Questionset {
    return this.elementQuestionSet;
  }

  setElementQuestionCheckBox(element: QuestionCheckBox): void {
    this.elementQuestionCheckBox = element;
  }

  getElementQuestionCheckBox(): QuestionCheckBox {
    return this.elementQuestionCheckBox;
  }

  setElementLocationSet(element: Locationset): void {
    this.elementLocationSet = element;
  }

  getElementLocationSet(): Locationset {
    return this.elementLocationSet;
  }

  setShowModal(showModal: boolean): void {
    this.localStorageService.setStoreOnLocalStorage(showModal);
    this.showModal = showModal;
    console.log(showModal);
  }

  getShowModal(): boolean {
    return this.localStorageService.getIsActiveTutorial();
  }
}
