<div class="container">
  <div class="row" >
    <div class="col-12">
      <h1>Sinu seiklus algab siit</h1>
    </div>
  </div>
  <div class="row buttons">
    <div class="col-lg-3 col-md-2"></div>
    <div class="col text-center">
      <button type="button" class="btn btn-dark rounded-pill" routerLink="/mang"><span>Liitu Ruumiga</span></button>
    </div>
    <div *ngIf="this.authService.getIsAuthenticated()" class="col text-center">
      <button type="button" class="btn btn-dark rounded-pill" [routerLink]="['/ruumid']"><span>Loo uus Ruum</span></button>
    </div>
    <div class="col-lg-3 col-md-2"></div>
  </div>
</div>
