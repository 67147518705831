import { ActiveRoomsService } from '../../services/active-rooms.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';
import {ModalService} from '../../services/modal.service';

@Component({
  selector: 'app-show-activated-room',
  templateUrl: './show-activated-room.component.html',
  styleUrls: ['./show-activated-room.component.css']
})
export class ShowActivatedRoomComponent implements OnInit {
  currentId;
  joinCode = '';
  constructor(private activatedRoute: ActivatedRoute,
              private location: Location,
              private activeRoomsService: ActiveRoomsService,
              private modalService: ModalService
              ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      (params) => {
        this.currentId = +params.activeroom;
        this.activeRoomsService.getActiveGameById(this.currentId).subscribe(room => {
          this.joinCode = room.joinCode;
        });
      });
  }
  deleteGame(): void{
    if (confirm('Kas olete kindel?')){
      this.activeRoomsService.deleteActiveGameByRoomCode(this.joinCode).subscribe(response => {
        this.joinCode = '';
        this.backClicked();
      }
    );
    }
  }
  backClicked(): void{
    this.location.back();
  }

  getShowModal(): boolean {
    return this.modalService.getShowModal();
  }

}
