import { Router } from '@angular/router';
import { ActiveRoomsService } from '../../services/active-rooms.service';
import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.css', '../greeting/greeting.component.css']
})
export class RoomComponent implements OnInit {
  @ViewChild('joinCode') joinCode: ElementRef;

  constructor(private router: Router,
              public acService: ActiveRoomsService
    ) { }

  ngOnInit(): void {
  }
  checkCode(): void{
    this.acService.getActiveGameByJoinCode(this.joinCode.nativeElement.value).subscribe(respone => {
      if (respone){
        this.router.navigate(['mang/' + this.joinCode.nativeElement.value]);
      }
      this.resetCode();
    },
      error => {
        this.resetCode();
        const errorMessage = error.message;
        console.error('Happened this during deleting: ', errorMessage);
    });
  }
  resetCode(): void{
    this.joinCode.nativeElement.value = '';
  }

}
