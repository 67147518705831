<jw-modal id="tutorialModal">
  <div class="col-sm-8 col-md-8 col-lg-8 form text-center mx-auto">
    <h2>{{modalTitle}}</h2>
    <div class="modal-body text-center" [innerHTML]="modalBody">

    </div>
    <div class="form-check">
      <input [(ngModel)]="isChecked" (change)="checkForIsShowModal(isChecked)" class="form-check-input" type="checkbox" id="flexCheckDefault">
      <label class="form-check-label" for="flexCheckDefault">
        Ära rohkem näita seda
      </label>
    </div>
    <div class="modal-footer">
      <button *ngIf="!isChecked" type="submit" (click)="closeModal('tutorialModal'); tutorialService.setTutorialActive(isTutorialActive)" class="btn btn-modal btn-success mx-auto">Alusta</button>
      <button type="button" class="btn btn-modal btn-secondary mx-auto" (click)="closeModal('tutorialModal'); tutorialService.setTutorialActive(false)">Sulge</button>
    </div>
  </div>
</jw-modal>
<button #modalClick type="button" class="d-none" (click)="openModal('tutorialModal');"></button>

