import { ModalService } from './../../services/modal.service';
import { MapControl } from './map-control';
import { Location } from '../../interfaces/location';
import { LocationService } from './../../services/location.service';
import { Component, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import { transform } from 'ol/proj.js';
import Geolocation from 'ol/Geolocation';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import View from 'ol/View';
import { fromLonLat } from 'ol/proj.js';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import { Circle as CircleStyle, Fill, Stroke, Icon, Style } from 'ol/style';
import { ActivatedRoute } from '@angular/router';
import { Location as URL } from '@angular/common';
import { defaults as defaultControls } from 'ol/control';
import { BackControl } from './back-control';
import { FormGroup } from '@angular/forms';

interface LonAndLat {
  lat: number;
  lng: number;
}

@Component({
  selector: 'app-create-map',
  templateUrl: './create-map.component.html',
  styleUrls: ['./create-map.component.css']
})
export class CreateMapComponent implements OnInit {

  @ViewChild('modal') modal: ElementRef;
  form: FormGroup;
  map;
  view;
  geolocation;
  player;
  vectorSource;
  vectorLayer: any;
  tileLayer: any;
  locationSetId;
  locations: Location[] = [];
  action = 'add';
  markers = [];
  isClose = false;
  closest = 0;
  positionFeature; // GPS Tracking
  accuracyFeature; // GPS Tracker Radius
  coordinates;
  locationFormat;
  coverRadius = 100;
  constructor(
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private url: URL,
    private locationService: LocationService) {
    this.locationSetId = this.activatedRoute.snapshot.paramMap.get('id');
  }
  ngOnInit(): void {
    this.initializeMap();
    this.getAllLocations();
  }
  backClicked(): void {
    this.url.back();
  }
  getAllLocations(): void {
    this.locationService.getLocations().subscribe(locations =>
      locations.forEach(location => {
        if (location.locationSetId.toString() === this.locationSetId) {
          this.addNewLocation(location);
        }
      }));
  }
  public checkRadius(): boolean {
    const features = this.vectorLayer.getSource().getFeatures();
    this.isClose = false;
    this.closest = 0;
    features.forEach(element => {
      const id = element.getId();
      if (id !== -1 && id !== 0) {
        const player = this.coordinates;
        const point = transform(element.getGeometry().getClosestPoint(this.player.getGeometry()
          .getCoordinates()), 'EPSG:3857', 'EPSG:4326');
        const distance = this.calculateD(player[0], player[1], point[0], point[1]);
        if (distance <= 100) {
          this.isClose = true;
          this.closest = distance;
          return this.isClose;
        }
      }
    });
    return this.isClose;
  }
  calculateD(lat1, lon1, lat2, lon2): number {  // generally used geo measurement function
    const R = 6378.137; // Radius of earth in KM
    const dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
    const dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180;
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d * 1000; // meters
  }

  addNewLocation(location: Location): void {
    const coordinates: LonAndLat = JSON.parse(location.location);
    this.createMarekrs(coordinates.lng, coordinates.lat, location.id);
  }
  getlocation(): void {
    this.accuracyFeature = new Feature();
    this.geolocation.on('change:accuracyGeometry', () => {
      this.accuracyFeature.setGeometry(this.geolocation.getAccuracyGeometry());
    });
    this.positionFeature.setId(-1);
    this.accuracyFeature.setId(-1);

    this.vectorSource.addFeature(this.positionFeature);
    this.vectorSource.addFeature(this.accuracyFeature);

    this.geolocation.setTracking(true);

    this.geolocation.on('change:position', () => {
      const coordinates = this.geolocation.getPosition();
      this.player.setGeometry(coordinates ? new Point(coordinates) : null);
      this.map.getView().setCenter(fromLonLat(coordinates, 'EPSG:4326', 'EPSG:3857'));
      this.geolocation.setTracking(false);
    });
  }
  getAllfeatures(list): void {
    const features = this.vectorSource.getFeatures();
    features.forEach(element => {
      if (element.getId() != null && element.getId() !== 0) {
        list.push(element);
      }
    });
  }
  createMarekrs(Lon, Lat, Id): Feature {
    const marker = new Feature({
      geometry: new Point(fromLonLat([Lon, Lat])),
      name: 'marker'
    });
    marker.setStyle(new Style({
      image: new Icon(({
        crossOrigin: 'anonymous',
        src: './assets/mapIcons/place.png',
        scale: 0.08,
      }))
    }));
    marker.setId(Id);
    this.vectorSource.addFeature(marker);
  }

  openModal(id: string): void {
    this.modalService.open(id);
  }
  closeModal(id: string): void {
    this.modalService.close(id);
  }
  createLocation(): void {
    if (this.coverRadius) {
      if (!this.checkRadius()) {
        const locationObj = {
          locationSetId: +this.locationSetId,
          location: this.locationFormat,
          coverRadius: this.coverRadius
        };
        this.locationService.postLocation(locationObj).subscribe(
          response => {
            this.createMarekrs(this.coordinates[0], this.coordinates[1], response.id);
            this.closeModal('addQuestion');
          },
          error => {
            const errorMessage = error.message;
            console.error('Happened this during posting: ', errorMessage);
          }
        );
      } else {
        this.closeModal('addQuestion');
        this.openModal('tooClose');
      }
    } else {
      alert('Sisestage Number');
    }
  }

  private initializeMap(): void {
    this.player = new Feature({
      geometry: new Point(fromLonLat([24.6675, 59.39503])),
      name: 'player'
    });
    this.player.setStyle(new Style({
      image: new Icon(({
        crossOrigin: 'anonymous',
        src: 'assets/mapIcons/arrow.png',
        scale: 0.08,
      }))
    }));
    this.player.setId(0);
    this.markers = [this.player];
    this.vectorSource = new VectorSource({
      features: this.markers
    });
    this.vectorLayer = new VectorLayer({
      source: this.vectorSource
    });
    this.tileLayer = new TileLayer({
      source: new OSM()
    });

    this.view = new View({
      center: fromLonLat([24.6675, 59.39503]),
      zoom: 16
    });

    this.map = new Map({
      controls: defaultControls().extend([new MapControl(), new BackControl(this.url)]),
      target: 'map',
      layers: [this.tileLayer, this.vectorLayer],
      view: this.view,
    });
    this.map.set('customMode', 'add');

    this.geolocation = new Geolocation({
      trackingOptions: {
        enableHighAccuracy: true,
      },
      projection: this.view.getProjection(),
    });

    this.positionFeature = new Feature();
    this.positionFeature.setStyle(
      new Style({
        image: new CircleStyle({
          radius: 5,
          fill: new Fill({
            color: '#3399CC',
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 2,
          }),
        }),
      })
    );
    this.getlocation();

    this.map.on('click', (data) => {
      this.action = this.map.get('customMode');
      this.coordinates = transform(data.coordinate, 'EPSG:3857', 'EPSG:4326');
      this.locationFormat = '{\"lat\" : ' + this.coordinates[1].toPrecision(9) + ', \"lng\" : ' + this.coordinates[0].toPrecision(9) + '}';
      if (this.action === 'add') {
        this.modalService.open('addQuestion');
      } else {
        this.map.forEachFeatureAtPixel(data.pixel, (feature) => {
          if (feature.getId() !== 0 && feature.getId() !== -1) {
            this.locationService.removeLocation(feature.getId()).subscribe({
              error: error => {
                const errorMessage = error.message;
                console.error('Happened this during deleting: ', errorMessage);
              }
            });
            this.vectorSource.removeFeature(feature);
          }
        });
      }
    });
  }
}
