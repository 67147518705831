<nav class="navbar navbar-expand-lg background-gradient-pink" *ngIf="nav.visible">
  <a class="navbar-brand order-0" routerLink="/avaleht">
    <img src="assets/logo_white.png" alt="" style="width: 155px">
  </a>
  <a #navbarToggler class="navbar-toggler collapsed border-0" type="button" data-toggle="collapse" data-target="#navbarSupportedContent,#navbarSupportedContent2">
    <span> </span>
    <span> </span>
    <span> </span>
  </a>

  <div class="collapse navbar-collapse w-100 order-1 snip1135" id="navbarSupportedContent">
    <ul class="navbar-nav mx-auto">
      <li class="nav-item mr-sm-5" [routerLinkActive]="['current']">
        <a class="btn" data-hover="Avaleht" routerLink="/avaleht" (click)="collapseNav()">Avaleht</a>
      </li>
      <li class="nav-item mr-sm-5" [routerLinkActive]="['current']">
        <a class="btn" data-hover="Mängima" routerLink="/mang" (click)="collapseNav()">Mängima</a>
      </li>
      <li class="nav-item mr-sm-5" [routerLinkActive]="['current']">
        <a class="btn" data-hover="KKK" routerLink="/kkk" (click)="collapseNav()" >KKK</a>
      </li>
      <li class="nav-item mr-sm-5" [routerLinkActive]="['current']">
        <a class="btn" data-hover="Kontakt" routerLink="/kontakt" (click)="collapseNav()">Kontakt</a>
      </li>
      <li *ngIf="this.authService.getIsAuthenticated()" class="nav-item dropdown mr-sm-5">
        <a class="btn profile" href="#" id="logoutdropdown" role="button" data-toggle="dropdown"
           aria-haspopup="true" aria-expanded="false">
          {{name}}
          <span class="dropdown-toggle"></span>
        </a>
        <div class="dropdown-menu dropdown-menu-right animate slideIn" aria-labelledby="logoutdropdown">
          <a class="dropdown-item text-dark text-center" routerLink="/ruumid">Loo Ruum</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-dark text-center" (click)="signOut()">Logi välja</a>
        </div>
      </li>
      <li *ngIf="!this.authService.getIsAuthenticated()" class="nav-item">
        <a class="btn" role="button" (click)="login()">Logi sisse</a>
      </li>
    </ul>
  </div>
</nav>
