<app-loading-screen *ngIf="showLoadingScreen"></app-loading-screen>
<div id="mainContainer" class="container-fluid p-0" [ngClass]="{'active': tutorialService.getTutorialActive(), 'mainPage': setBgImgAndHideNavBar()}">
  <div class="row m-0">
    <div class="col-12 p-0">
      <app-navbar></app-navbar>
    </div>
  </div>
<!--  <app-splash-screen [animationType]="'fade-out'" [duration]="2" [animationDuration]="0.5"></app-splash-screen>-->
  <router-outlet></router-outlet>
</div>
