import { Locationset } from '../../interfaces/locationset';
import { LocationsetsService } from '../../services/locationsets.service';
import { Component, OnInit } from '@angular/core';
import {ModalService} from '../../services/modal.service';
import {Router} from '@angular/router';
import {TutorialService} from '../../services/tutorial.service';
import {OAuthService} from '../../services/o-auth.service';

@Component({
  selector: 'app-location-sets',
  templateUrl: './location-sets.component.html',
  styleUrls: ['./location-sets.component.css', '../../app.component.css']
})
// TODO: Refresh page after succesful post and delete.
export class LocationSetsComponent implements OnInit {
  data: Array<any>;
  locationSets: Locationset[] = [];
  redirectTo: Locationset;

  constructor(private locationSetsService: LocationsetsService,
              public modalService: ModalService,
              private router: Router,
              private oauthService: OAuthService,
              public tutorialService: TutorialService) {
  }

  ngOnInit(): void {
    this.getLocationSets(true);
  }
  getLocationSets(firstLoad: boolean): void{
    this.locationSetsService.getLocationSetsByAccountId(this.oauthService.getCurrentAccount().id).subscribe(locationSets => {
      this.locationSets = locationSets;
    },
      (error => alert(error)),
      () => {
        if (!firstLoad) {
          this.redirectTo = this.locationSets.slice(-1)[0];
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate(['createlocation', this.redirectTo.id]);
          });
        }
      });
  }

  removeLocationSet(set: Locationset): void{
    this.locationSetsService.removeLocationSet(set.id).subscribe(() => {
      this.getLocationSets(false);
      window.location.reload();
      },
      error => {
        const errorMessage = error.message;
        console.error('Happened this during deleting: ', errorMessage);
    });
  }
  addLocationSet(): void{
    const locationSetName = (document.getElementById('input') as HTMLInputElement).value;
    this.locationSetsService.addLocationSet({name: locationSetName, creatorId: this.oauthService.getCurrentAccount().id} as Locationset)
      .subscribe(() => {
        this.getLocationSets(false);
        // window.location.reload();
        },
        error => {
          const errorMessage = error.message;
          console.error('Happened this during posting: ', errorMessage);
        });
  }

  openModalForDeleting(id: string, element: Locationset): void {
    this.modalService.open(id);
    this.modalService.setElementLocationSet(element);
  }

  openModalForCreating(id: string): void {
    this.modalService.open(id);
  }

  closeModal(id: string): void {
    this.modalService.close(id);
  }

  getShowModal(): boolean {
    return this.modalService.getShowModal();
  }
}
