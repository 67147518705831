import {OAuthService} from '../../services/o-auth.service';
import { Subscription } from 'rxjs';
import { PairsetsService } from './../../services/pairsets.service';
import { RoomService } from './../../services/room.service';
import { Room } from '../../interfaces/room';
import { QuestionSetsService } from '../../services/question-sets.service';
import { QuestionService } from '../../services/question.service';
import { Questionset } from '../../interfaces/questionset';
import { PairService } from '../../services/pair.service';
import { BackControl } from '../create-map/back-control';
import { ModalService } from '../../services/modal.service';
import { Location } from '../../interfaces/location';
import { LocationService } from '../../services/location.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import Geolocation from 'ol/Geolocation';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import View from 'ol/View';
import {fromLonLat} from 'ol/proj.js';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import {Icon, Style} from 'ol/style';
import { ActivatedRoute } from '@angular/router';
import {Location as URL} from '@angular/common';
import {defaults as defaultControls} from 'ol/control';
import { FormGroup} from '@angular/forms';
import { Pair } from 'src/app/interfaces/pair';
import { Question } from 'src/app/interfaces/question';
import { Pairset } from 'src/app/interfaces/pairset';

interface LonAndLat {
  lat: number;
  lng: number;
}

@Component({
  selector: 'app-pair-set-gen',
  templateUrl: './pair-set-gen.component.html',
  styleUrls: ['./pair-set-gen.component.css']
})
export class PairSetGenComponent implements OnInit, OnDestroy{

  @ViewChild('modal') modal: ElementRef;
  questionSetdata: Questionset[] = [];
  selectedQuestionSet;
  selectedQuestion;
  selectedLocation;
  selectedPair;
  questionText;
  questions: Question[] = [];
  form: FormGroup;
  pngUrl;
  map;
  view;
  geolocation;
  player;
  vectorSource;
  vectorLayer: any;
  tileLayer: any;
  roomId;
  locationSetId = 0;
  selectedPairSetId;
  room: Room;
  pairSets: Pairset[] = [];
  locations: Location[] = [];
  markers = [];
  selectedFeature: Feature;
  listOfLocationIds = [];
  listOfChangedLocationIds = [];
  private subscriptions: Subscription[] = [];
  accuracyFeature; // GPS Tracker Radius
  constructor(private activatedRoute: ActivatedRoute,
              private modalService: ModalService,
              private urlLocation: URL,
              private roomService: RoomService,
              private pairService: PairService,
              private pairsetS: PairsetsService,
              private questionService: QuestionService,
              private questionSetsService: QuestionSetsService,
              private oAuthService: OAuthService,
              private locationService: LocationService) {
                this.activatedRoute.parent.params.subscribe(
                  (params) => {
                    this.roomId = +params.id;
                    this.getRoom();
                  });
                this.locationSetId = +this.activatedRoute.snapshot.paramMap.get('set');
  }

  ngOnInit(): void {
    this.initializeMap();
    this.getAllLocations();
    this.getPairSets();
  }
  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  getRoom(): void{
    const sub = this.roomService.getRoomById(this.roomId).subscribe(room => this.room = room);
    this.subscriptions.push(sub);
  }

  getPairSets(): void{
    const sub = this.pairsetS.getAllOriginalPairsetsByRoomId(this.roomId).subscribe(sets => this.pairSets = sets);
    this.subscriptions.push(sub);
  }
  resetForm(): void{
    this.closeModal('createNewPair');
  }
  resetPairForm(): void{
    this.selectedPair = undefined;
    this.questionText = undefined;
    this.closeModal('pairData');
  }
  // Function used to generate a new pairset
  createPairSet(): void{
    if (this.room.teamLimit > this.pairSets.length){
      const pairSetObj = {
        roomId: this.roomId,
        type: 'original'
      };
      const sub = this.pairsetS.createPairset(pairSetObj).subscribe(set => {
        this.pairSets.push(set);
      });
      this.subscriptions.push(sub);
    }else{
      alert('Ei tohi rohkem sette olla');
    }
  }
  // Function used to copy an existing pairset if one is selected.
  copyPairSet(): void{
    if (this.room.teamLimit > this.pairSets.length && this.selectedPairSetId){
      const pairSetObj = {
        type: 'original'
      };
      const sub = this.pairsetS.copyPairset(this.selectedPairSetId, pairSetObj).subscribe(set => {
        this.pairSets.push(set);
      });
      this.subscriptions.push(sub);
    }else{
      alert('Ei tohi rohkem sette olla');
    }
  }
  // Function that is called when a new pairset is selected. Deletes pairs and adds pairs inside the pairset.
  drawNewPairs(id: number): void{
    this.selectedPairSetId = id;
    const features = this.vectorLayer.getSource().getFeatures();
    features.forEach(feature => {
      if (this.listOfChangedLocationIds.includes(feature.getId())) {
        feature.set('pairId', undefined);
        feature.setStyle(new Style({
        image: new Icon(({
          crossOrigin: 'anonymous',
          src: './assets/mapIcons/place.png',
          scale: 0.08,
        }))
        }));
        const index = this.listOfChangedLocationIds.indexOf(feature.getId(), 0);
        if (index > -1) {
          this.listOfChangedLocationIds.splice(index, 1);
        }
      }else if (feature.get('pairId')){
        this.deleteFromMap(feature);
      }
    });
    this.listOfChangedLocationIds = [];
    this.getPairsBySet(id);
  }

  postPair(): void{
    if (this.selectedPairSetId){
      if (this.selectedQuestionSet && this.selectedQuestion){
        const pairObj = {
          questionId: this.selectedQuestion,
          locationId: this.selectedLocation,
          pairSetId: this.selectedPairSetId,
          disappearType: 'CORRECT',
        };
        const sub = this.pairService.postPair(pairObj).subscribe(respone => {
          this.listOfChangedLocationIds.push(respone.locationId);
          this.selectedFeature.set('pairId', respone.id);
          this.selectedFeature.setStyle(new Style({
            image: new Icon(({
              crossOrigin: 'anonymous',
              src: './assets/mapIcons/pair.png',
              scale: 0.08,
            }))
          }));
          this.resetForm();
        });
        this.subscriptions.push(sub);
      }else{
        alert('Täida väljad');
      }
    }else{
      alert('Vali paaristik');
    }
  }
  updateQuestions(): void{
    const sub = this.questionService.getQuestions(this.selectedQuestionSet).subscribe(questions => {
      this.questions = questions;
    });
    this.subscriptions.push(sub);
  }
  createPair(): void{
    const sub = this.questionSetsService.getQuestionSetsByUserId(this.oAuthService.getCurrentAccount().id).subscribe( sets => {
      this.questionSetdata = sets;
      if (this.questionSetdata){
        this.openModal('createNewPair');
      }
    });
    this.subscriptions.push(sub);
  }

  editPair(): void{
    const sub = this.pairService.getPairbyId(this.selectedPair).subscribe(pair => {
      const subscription = this.questionService.getQuestionById(pair.questionId).subscribe(question => {
        this.questionText = question.questionText;
        // TODO: Add abilty to edit pair
        this.openModal('pairData');
      });
      this.subscriptions.push(subscription);
    });
    this.subscriptions.push(sub);
  }
  deletePair(): void{
    const sub = this.pairService.removePair(this.selectedPair).subscribe( () => {
      this.selectedFeature.set('pairId', undefined);
      this.selectedFeature.setStyle(new Style({
        image: new Icon(({
          crossOrigin: 'anonymous',
          src: './assets/mapIcons/place.png',
          scale: 0.08,
        }))
      }));
      this.resetPairForm();
    });
    this.subscriptions.push(sub);
  }
  changeLocationToPair(pair: Pair): void{
    const features = this.vectorLayer.getSource().getFeatures();
    features.forEach(feature => {
      if (feature.getId() === pair.locationId){
        this.listOfChangedLocationIds.push(pair.locationId);
        feature.set('pairId', pair.id);
        feature.setStyle(new Style({
        image: new Icon(({
          crossOrigin: 'anonymous',
          src: './assets/mapIcons/pair.png',
          scale: 0.08,
        }))
      }));
      }
    });
  }
  getPairsBySet(id: number): void{
    const sub = this.pairService.getPairsByPairSetId(id).subscribe( pairs => {
      if (pairs) {
        pairs.forEach(pair => {
          if (this.listOfLocationIds.includes(pair.locationId)){
            this.changeLocationToPair(pair);
          }else{
            this.addNewPairLocation(pair);
          }
        });
      }
    });
    this.subscriptions.push(sub);
  }
  deleteFromMap(feature: Feature): void{
    this.vectorSource.removeFeature(feature);
  }

  getAllLocations(): void{
    const sub = this.locationService.getLocationsBySet(this.locationSetId).subscribe(locations =>
      locations.forEach(location => {
        if (!this.listOfLocationIds.includes(location.id)){
          this.listOfLocationIds.push(location.id);
          this.addNewLocation(location);
        }
      }));
    this.subscriptions.push(sub);
  }
  addNewPairLocation(pair: Pair): void{
    const sub = this.locationService.getLocationsbyId(pair.locationId).subscribe(location => {
      const coordinates: LonAndLat = JSON.parse(location.location);
      this.createMarekrs(coordinates.lng, coordinates.lat, location.id, pair.id);
    });
    this.subscriptions.push(sub);
  }

  addNewLocation(location: Location): void{
    const coordinates: LonAndLat = JSON.parse(location.location);
    this.createMarekrs(coordinates.lng, coordinates.lat, location.id, null);
  }

  getlocation(): void{
    this.accuracyFeature = new Feature();
    this.geolocation.on('change:accuracyGeometry', () => {
    this.accuracyFeature.setGeometry(this.geolocation.getAccuracyGeometry());
    });
    this.accuracyFeature.setId(-1);

    this.vectorSource.addFeature(this.accuracyFeature);

    this.geolocation.setTracking(true);

    this.geolocation.on('change:position', () => {
      const coordinates = this.geolocation.getPosition();
      this.player.setGeometry(coordinates ? new Point(coordinates) : null);
      this.map.getView().setCenter(fromLonLat(coordinates, 'EPSG:4326', 'EPSG:3857'));
      this.geolocation.setTracking(false);
    });
  }

  createMarekrs(Lon, Lat, Id, pairId): Feature{
    this.pngUrl = './assets/mapIcons/place.png';
    const marker = new Feature({
      geometry: new Point(fromLonLat([Lon, Lat])),
      name: 'marker'
    });
    if (pairId){
      this.pngUrl = './assets/mapIcons/pair.png';
      marker.set('pairId', pairId);
    }
    marker.setStyle(new Style({
      image: new Icon(({
        crossOrigin: 'anonymous',
        src: this.pngUrl,
        scale: 0.08,
      }))
    }));
    marker.setId(Id);
    this.vectorSource.addFeature(marker);
  }

  openModal(id: string): void {
    this.modalService.open(id);
  }
  closeModal(id: string): void {
    this.modalService.close(id);
  }

  private initializeMap(): void{
    this.player = new Feature({
      geometry: new Point(fromLonLat([24.6675, 59.39503])),
      name: 'player'
    });
    this.player.setStyle(new Style({
      image: new Icon(({
        crossOrigin: 'anonymous',
        src: 'assets/mapIcons/arrow.png',
        scale: 0.08,
      }))
    }));
    this.player.setId(0);
    this.markers = [this.player];
    this.vectorSource = new VectorSource({
      features: this.markers
    });
    this.vectorLayer = new VectorLayer({
      source: this.vectorSource
    });
    this.tileLayer = new TileLayer({
      source: new OSM()
    });

    this.view = new View({
      center: fromLonLat([24.6675, 59.39503]),
      zoom: 16
    });

    this.map = new Map({
      controls: defaultControls().extend([new BackControl(this.urlLocation)]),
      target: 'map',
      layers: [this.tileLayer, this.vectorLayer],
      view: this.view,
    });
    this.map.set('customMode', 'add');

    this.geolocation = new Geolocation({
      trackingOptions: {
        enableHighAccuracy: true,
      },
      projection: this.view.getProjection(),
    });
    this.getlocation();
    this.map.on('click', (data) => {
        this.map.forEachFeatureAtPixel(data.pixel, (feature) => {
          if (feature.getId() >= 1){
            this.selectedLocation = feature.getId();
            this.selectedFeature = feature;
            if (feature.get('pairId')){
              this.selectedPair = feature.get('pairId');
              this.editPair();
            }else{
              this.createPair();
            }
          }
        });
    });
  }
}
